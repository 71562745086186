import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, Form, Input, Button, message, Modal} from 'antd';
import SliderCaptcha from 'rc-slider-captcha';
import {getCaptcha, verifyCaptcha} from './service';
import './help.scss';
import {request} from '@/utils';

const sendVerificationCode = async (email) => {
  try {
    const response = await request.post(`/password-recovery/send-code?email=${encodeURIComponent(email)}`);
    if (response.status === '200') {
      message.success('验证码已发送，请检查您的邮箱。');
      return response;
    } else if (response.status === '400') {
      message.error('邮箱格式不正确。');
      throw new Error(response.message);
    } else if (response.status === '404') {
      message.error('这个邮箱地址没有注册用户。');
      throw new Error(response.message);
    } else if (response.status === '405') {
      message.error('用户已被删除或被禁止。');
      throw new Error(response.message);
    } else if (response.status === '409') {
      message.error('此邮箱地址对应多个用户，请联系管理员。');
      throw new Error(response.message);
    } else {
      message.error(response.message);
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Error sending verification code:', error);
    throw error;
  }
};

const verifyCode = async (email, code) => {
  try {
    const response = await request.post(`/password-recovery/verify-code?email=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}`);
    if (response.status === '200') {
      message.success('密码已经重置为初始密码。');
      return response;
    } else if (response.status === '400') {
      message.error('邮箱格式不正确。');
      throw new Error(response.message);
    } else if (response.status === '401') {
      message.error('验证码错误或已过期。');
      throw new Error(response.message);
    } else if (response.status === '409') {
      message.error('此邮箱地址对应多个用户，请联系管理员。');
      throw new Error(response.message);
    } else {
      message.error(response.message);
      throw new Error(response.message);
    }
  } catch (error) {
    console.error('Error verifying code:', error);
    throw error;
  }
};

const Help = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const [disableVerifyButton, setDisableVerifyButton] = useState(false);

  const onFinish = (values) => {
    setFormValues(values);
    setCaptchaVisible(true);
  };

  const handleCaptchaSuccess = async () => {
    setCaptchaVisible(false);
    setCaptchaPassed(true);
    setLoading(true);
    setCanResend(false);
    setTimer(60);

    try {
      await sendVerificationCode(formValues.email);
      message.success('認証コードが送信されました。メールを確認してください。');
    } catch (error) {
      message.error('メール送信に失敗しました。');
    } finally {
      setLoading(false);
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setCanResend(true);
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const handleVerify = async (values) => {
    setVerifyLoading(true);
    setDisableVerifyButton(true); // 提交后禁用按钮
    try {
      await verifyCode(formValues.email, values.verificationCode);
      message.success('パスワードがリセットされました。新しいパスワードは電話番号の後四桁です。再ログインして変更してください。');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      message.error('認証に失敗しました。正しいコードを入力してください。');
      setDisableVerifyButton(false); // 如果失败，允许再次点击
    } finally {
      setVerifyLoading(false);
    }
  };


  return (<div className="help-page">
      <Card className="help-card">
        <h1 className="help-title">パスワードリセット</h1>
        <p className="help-description">
          メールアドレスを入力し、認証コードを取得してください
          <br />
          <span className="help-warning">（完成后密码将会恢复为您登陆时的電話番号后四位，登陆成功后请及时修改密码）</span>
        </p>
        <Form className="help-form" onFinish={onFinish} layout="vertical">
          <Form.Item name="email" label="メールアドレス"
                     rules={[{required: true, message: 'メールアドレスを入力してください'}, {
                       type: 'email', message: '有効なメールアドレスを入力してください',
                     }]}>
            <Input className="help-input" placeholder="メールアドレスを入力してください" disabled={captchaPassed} />
          </Form.Item>
          <Form.Item>
            <Button className="help-button-primary" type="primary" htmlType="submit" loading={loading}
                    disabled={!canResend} block>
              認証コードを送信{!canResend && ` (${timer}s)`}
            </Button>
          </Form.Item>
        </Form>
        {captchaPassed && (<Form className="help-form" onFinish={handleVerify} layout="vertical">
          <Form.Item
            name="verificationCode"
            label="認証コード"
            rules={[{required: true, message: '認証コードを入力してください'}]}
          >
            <Input className="help-input" placeholder="メールで受信した認証コードを入力してください" />
          </Form.Item>
          <Form.Item>
            <Button
              className="help-button-primary"
              type="primary"
              htmlType="submit"
              loading={verifyLoading}
              disabled={disableVerifyButton}
              block
            >
              パスワードをリセット
            </Button>
          </Form.Item>
        </Form>)}
      </Card>
      <Modal
        className="help-captcha-modal"
        title="検証"
        visible={captchaVisible}
        footer={null}
        onCancel={() => setCaptchaVisible(false)}
        width={370} // 设置Modal的宽度，单位为px
      >
        <SliderCaptcha
          request={getCaptcha}
          onVerify={(data) => {
            return verifyCaptcha(data)
              .then(() => {
                message.success('検証に成功しました。');
                setCaptchaVisible(false);
                handleCaptchaSuccess();
                return Promise.resolve();
              })
              .catch(() => {
                message.error('検証に失敗しました。もう一度お試しください。');
                return Promise.reject();
              });
          }}
          onSuccess={handleCaptchaSuccess}
        />
      </Modal>
    </div>

  );
};

export default Help;
