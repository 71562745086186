// service.js 文件
import { sleep } from 'ut2';
import ImageBg from '@/assets/images/1.png';
import ImagePuzzle from '@/assets/images/2.png';

export const getCaptcha = async () => {
  await sleep();
  return {
    bgUrl: ImageBg,
    puzzleUrl: ImagePuzzle
  };
};

export const verifyCaptcha = async (data) => {
  await sleep();
  if (data?.x && data.x > 87 && data.x < 93) {
    return Promise.resolve();
  }
  return Promise.reject();
};

export default {};