import React from 'react';
import {MailOutlined} from '@ant-design/icons';
import {Button, Popconfirm, message} from 'antd';
import {request} from '@/utils';

const SendMailButton = ({selectedUserIds, total}) => {
  const handleSendMail = async () => {
    try {
      const userIds = selectedUserIds.length > 0 ? selectedUserIds : [];
      const response = await request.post('/admin/sendMailByUserIds', userIds);

      if (response || response.data || response.data.status === '200') {
        message.success('メール送信成功');
      } else {
        message.error('メール送信失敗');
      }
    } catch (error) {
      message.error('メール送信失敗: ' + (error.response?.data?.message || error.message));
    }
  };

  return (<Popconfirm
    title={`${selectedUserIds.length > 0 ? selectedUserIds.length : total}人にメールを送信することを確認しますか?`}
    onConfirm={handleSendMail}
    okText="送信"
    cancelText="キャンセル"
  >
    <Button type="primary" icon={<MailOutlined />}>メール送信</Button>
  </Popconfirm>);
};

export default SendMailButton;
