import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider } from 'react-redux'
import store from './store';
import 'normalize.css'
import VersionChecker from '@/components/VersionChecker';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <VersionChecker />
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </Provider>

) 