import React, {useEffect, useState} from 'react';
import {request} from '@/utils';
import {EditOutlined} from '@ant-design/icons';
import './UserSecond.css';
import {Button, message, Tooltip} from 'antd';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {clearUserInfo} from '@/store/modules/user';
import ChangePasswordModal from './ChangePasswordModal'; // 引用自定义模态框组件
import ActuarialDetailsModal from './ActuarialDetailsModal'; // 引用精算金額詳細模态框组件
import UpdateEmailModal from './UpdateEmailModal'; // 导入修改邮箱的 Modal 组件
import CryptoJS from 'crypto-js';

const UserSecond = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isActuarialModalVisible, setIsActuarialModalVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await request.get('/user/getUserDetails');
        if (response.status === '200') {
          setUserData(response.data);
        } else {
          alert('ユーザー情報の取得に失敗しました');
        }
      } catch (error) {
        alert('リクエストエラー: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleActuarialModalCancel = () => {
    setIsActuarialModalVisible(false);
  };

  const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456');
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  };

  const handlePasswordChange = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('新しいパスワードが一致しません');
      return;
    }

    try {
      const encryptedCurrentPassword = encryptPassword(values.currentPassword);
      const encryptedNewPassword = encryptPassword(values.newPassword);

      const response = await request.post('/user/changePassword', {
        userId: values.userId,
        username: values.username,
        currentPassword: encryptedCurrentPassword,
        newPassword: encryptedNewPassword,
      });
      const {status, message: responseMessage} = response;
      if (status === '200') {
        message.success('パスワードが変更されましたので、再度ログインしてください');
        setIsModalVisible(false);
        onConfirm();
      } else {
        message.error(responseMessage || 'パスワードの変更に失敗しました');
      }
    } catch (error) {
      const {response} = error;
      const errorMessageMap = {
        400: 'ユーザー名が一致しません',
        401: '現在のパスワードが正しくありません',
        402: '新しいパスワードは古いパスワードと同じにしてはいけません',
        403: '新しいパスワードは8文字以上18文字以下で、数字と英字を両方含む必要があります',
      };
      const errorMessage = errorMessageMap[response?.status] || 'リクエストエラー: ' + error.message;
      message.error(errorMessage);
    }
  };

  const onConfirm = () => {
    dispatch(clearUserInfo());
    navigate('/login');
  };

  if (loading) {
    return <div className="loading-spinner">読み込み中...</div>;
  }

  const showEmailModal = () => {
    setEmailModalVisible(true);
  };

  const handleEmailModalCancel = () => {
    setEmailModalVisible(false);
  };

  return (<div className="user-second-container">
    <div className="user-second-card">
      <h2 className="user-second-title">ガイド詳細設定</h2>
      <div className="user-second-descriptions">
        <div className="description-item">
          <span className="description-label">利用者ID:</span>
          <span className="description-content">{userData.userId}</span>
        </div>
        <div className="description-item">
          <span className="description-label">アカウント(電話番号):</span>
          <span className="description-content">{userData.userAccount}</span>
        </div>
        <div className="description-item">
          <span className="description-label">メールアドレス:</span>
          <span className="description-content">{userData.userEmail}</span>
          <Tooltip title="メールアドレス更新">
            <Button
              type="link"
              onClick={showEmailModal}
              icon={<EditOutlined />}
              style={{
                padding: '0 8px', color: '#1890ff', fontSize: '14px', fontWeight: 'bold',
              }}
            >
              更新
            </Button>
          </Tooltip>
          <UpdateEmailModal
            visible={emailModalVisible}
            onCancel={handleEmailModalCancel}
            userId={userData.userId}
            userEmail={userData.userEmail}
          />
        </div>
        <div className="description-item">
          <span className="description-label">パスワード変更状態:</span>
          <span className="description-content">{userData.passwordChanged ? '変更済み' : '変更待ち'}</span>
        </div>
        <div className="description-item">
          <span className="description-label">パスワード変更日時:</span>
          <span className="description-content">{userData.passwordChangeTime || '変更待ち'}</span>
        </div>
        <div className="description-item">
          <span className="description-label">精算先(利用者):</span>
          <span className="description-content">{userData.payee}</span>
        </div>
        <div className="description-item">
          <span className="description-label">総決算金額(精算待ち):</span>
          <span
            className={`description-content ${userData.finalTotal >= 0 ? 'positive' : 'negative'}`}>
            {userData.passwordChanged ? `￥${new Intl.NumberFormat('ja-JP').format(userData.finalTotal)}` :
              <span style={{color: 'red', fontSize: '0.63em'}}>パスワードを変更してください</span>}
          </span>
        </div>
        <div className="description-item">
          <span className="description-label">金融機関名:</span>
          <span className="description-content">{userData.bankName}</span>
        </div>
        <div className="description-item">
          <span className="description-label">支店コード:</span>
          <span className="description-content">{userData.branchCode}</span>
        </div>
        <div className="description-item">
          <span className="description-label">支店名:</span>
          <span className="description-content">{userData.branchName}</span>
        </div>
        <div className="description-item">
          <span className="description-label">口座番号:</span>
          <span className="description-content">{userData.accountNumber}</span>
        </div>
        <div className="description-item">
          <span className="description-label">口座名義人:</span>
          <span className="description-content">{userData.accountHolderName}</span>
        </div>
      </div>
      <div className="button-container">
        <Button
          type="primary"
          onClick={() => setIsActuarialModalVisible(true)}
          style={{
            backgroundColor: userData.passwordChanged ? '#1890ff' : '#d9d9d9',
            borderColor: userData.passwordChanged ? '#1890ff' : '#d9d9d9',
          }}
          className="actuarial-details-button1"
          disabled={!userData.passwordChanged}
        >
          精算金額詳細
        </Button>
        <Button
          type="primary"
          onClick={showModal}
          style={{backgroundColor: '#ff4d4f', borderColor: '#ff4d4f'}}
          className="password-change-button1"
        >
          パスワード変更
        </Button>
      </div>
      <ChangePasswordModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onFinish={handlePasswordChange}
        userId={userData.userId}
        username={userData.userAccount}
      />
      <ActuarialDetailsModal
        visible={isActuarialModalVisible}
        onCancel={handleActuarialModalCancel}
        actuarialDetails={userData}
      />
    </div>
  </div>);
};

export default UserSecond;
