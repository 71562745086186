// bankOptions.js
export const bankOptions = [
  { code: '0001', nameKanji: 'みずほ銀行', nameKatakana: 'ミズホギンコウ' },
  { code: '0005', nameKanji: '三菱UFJ銀行', nameKatakana: 'ミツビシユ－エフジエイギンコウ' },
  { code: '0009', nameKanji: '三井住友銀行', nameKatakana: 'ミツイスミトモギンコウ' },
  { code: '0010', nameKanji: 'りそな銀行', nameKatakana: 'リソナギンコウ' },
  { code: '0017', nameKanji: '埼玉りそな銀行', nameKatakana: 'サイタマリソナギンコウ' },
  { code: '0033', nameKanji: 'PayPay銀行', nameKatakana: 'ペイペイギンコウ' },
  { code: '0034', nameKanji: 'セブン銀行', nameKatakana: 'セブンギンコウ' },
  { code: '0035', nameKanji: 'ソニー銀行', nameKatakana: 'ソニ－ギンコウ' },
  { code: '0036', nameKanji: '楽天銀行', nameKatakana: 'ラクテンギンコウ' },
  { code: '0038', nameKanji: '住信SBIネット銀行', nameKatakana: 'スミシンエスビ－アイネツトギンコウ' },
  { code: '0039', nameKanji: 'auじぶん銀行', nameKatakana: 'エ－ユ－ジブンギンコウ' },
  { code: '0040', nameKanji: 'イオン銀行', nameKatakana: 'イオンギンコウ' },
  { code: '0041', nameKanji: '大和ネクスト銀行', nameKatakana: 'ダイワネクストギンコウ' },
  { code: '0042', nameKanji: 'ローソン銀行', nameKatakana: 'ロ－ソンギンコウ' },
  { code: '0043', nameKanji: 'みんなの銀行', nameKatakana: 'ミンナノギンコウ' },
  { code: '0044', nameKanji: 'UI銀行', nameKatakana: 'ユ－アイギンコウ' },
  { code: '0116', nameKanji: '北海道銀行', nameKatakana: 'ホツカイドウギンコウ' },
  { code: '0117', nameKanji: '青森銀行', nameKatakana: 'アオモリギンコウ' },
  { code: '0118', nameKanji: 'みちのく銀行', nameKatakana: 'ミチノクギンコウ' },
  { code: '0119', nameKanji: '秋田銀行', nameKatakana: 'アキタギンコウ' },
  { code: '0120', nameKanji: '北都銀行', nameKatakana: 'ホクトギンコウ' },
  { code: '0121', nameKanji: '荘内銀行', nameKatakana: 'シヨウナイギンコウ' },
  { code: '0122', nameKanji: '山形銀行', nameKatakana: 'ヤマガタギンコウ' },
  { code: '0123', nameKanji: '岩手銀行', nameKatakana: 'イワテギンコウ' },
  { code: '0124', nameKanji: '東北銀行', nameKatakana: 'トウホクギンコウ' },
  { code: '0125', nameKanji: '七十七銀行', nameKatakana: 'シチジユウシチギンコウ' },
  { code: '0126', nameKanji: '東邦銀行', nameKatakana: 'トウホウギンコウ' },
  { code: '0128', nameKanji: '群馬銀行', nameKatakana: 'グンマギンコウ' },
  { code: '0129', nameKanji: '足利銀行', nameKatakana: 'アシカガギンコウ' },
  { code: '0130', nameKanji: '常陽銀行', nameKatakana: 'ジヨウヨウギンコウ' },
  { code: '0131', nameKanji: '筑波銀行', nameKatakana: 'ツクバギンコウ' },
  { code: '0133', nameKanji: '武蔵野銀行', nameKatakana: 'ムサシノギンコウ' },
  { code: '0134', nameKanji: '千葉銀行', nameKatakana: 'チバギンコウ' },
  { code: '0135', nameKanji: '千葉興業銀行', nameKatakana: 'チバコウギヨウギンコウ' },
  { code: '0137', nameKanji: 'きらぼし銀行', nameKatakana: 'キラボシギンコウ' },
  { code: '0138', nameKanji: '横浜銀行', nameKatakana: 'ヨコハマギンコウ' },
  { code: '0140', nameKanji: '第四北越銀行', nameKatakana: 'ダイシホクエツギンコウ' },
  { code: '0142', nameKanji: '山梨中央銀行', nameKatakana: 'ヤマナシチユウオウギンコウ' },
  { code: '0143', nameKanji: '八十二銀行', nameKatakana: 'ハチジユウニギンコウ' },
  { code: '0144', nameKanji: '北陸銀行', nameKatakana: 'ホクリクギンコウ' },
  { code: '0145', nameKanji: '富山銀行', nameKatakana: 'トヤマギンコウ' },
  { code: '0146', nameKanji: '北國銀行', nameKatakana: 'ホツコクギンコウ' },
  { code: '0147', nameKanji: '福井銀行', nameKatakana: 'フクイギンコウ' },
  { code: '0149', nameKanji: '静岡銀行', nameKatakana: 'シズオカギンコウ' },
  { code: '0150', nameKanji: 'スルガ銀行', nameKatakana: 'スルガギンコウ' },
  { code: '0151', nameKanji: '清水銀行', nameKatakana: 'シミズギンコウ' },
  { code: '0152', nameKanji: '大垣共立銀行', nameKatakana: 'オオガキキヨウリツギンコウ' },
  { code: '0153', nameKanji: '十六銀行', nameKatakana: 'ジユウロクギンコウ' },
  { code: '0154', nameKanji: '三十三銀行', nameKatakana: 'サンジユウサンギンコウ' },
  { code: '0155', nameKanji: '百五銀行', nameKatakana: 'ヒヤクゴギンコウ' },
  { code: '0157', nameKanji: '滋賀銀行', nameKatakana: 'シガギンコウ' },
  { code: '0158', nameKanji: '京都銀行', nameKatakana: 'キヨウトギンコウ' },
  { code: '0159', nameKanji: '関西みらい銀行', nameKatakana: 'カンサイミライギンコウ' },
  { code: '0161', nameKanji: '池田泉州銀行', nameKatakana: 'イケダセンシユウギンコウ' },
  { code: '0162', nameKanji: '南都銀行', nameKatakana: 'ナントギンコウ' },
  { code: '0163', nameKanji: '紀陽銀行', nameKatakana: 'キヨウギンコウ' },
  { code: '0164', nameKanji: '但馬銀行', nameKatakana: 'タジマギンコウ' },
  { code: '0166', nameKanji: '鳥取銀行', nameKatakana: 'トツトリギンコウ' },
  { code: '0167', nameKanji: '山陰合同銀行', nameKatakana: 'サンインゴウドウギンコウ' },
  { code: '0168', nameKanji: '中国銀行', nameKatakana: 'チユウゴクギンコウ' },
  { code: '0169', nameKanji: '広島銀行', nameKatakana: 'ヒロシマギンコウ' },
  { code: '0170', nameKanji: '山口銀行', nameKatakana: 'ヤマグチギンコウ' },
  { code: '0172', nameKanji: '阿波銀行', nameKatakana: 'アワギンコウ' },
  { code: '0173', nameKanji: '百十四銀行', nameKatakana: 'ヒヤクジユウシギンコウ' },
  { code: '0174', nameKanji: '伊予銀行', nameKatakana: 'イヨギンコウ' },
  { code: '0175', nameKanji: '四国銀行', nameKatakana: 'シコクギンコウ' },
  { code: '0177', nameKanji: '福岡銀行', nameKatakana: 'フクオカギンコウ' },
  { code: '0178', nameKanji: '筑邦銀行', nameKatakana: 'チクホウギンコウ' },
  { code: '0179', nameKanji: '佐賀銀行', nameKatakana: 'サガギンコウ' },
  { code: '0181', nameKanji: '十八親和銀行', nameKatakana: 'ジユウハチシンワギンコウ' },
  { code: '0182', nameKanji: '肥後銀行', nameKatakana: 'ヒゴギンコウ' },
  { code: '0183', nameKanji: '大分銀行', nameKatakana: 'オオイタギンコウ' },
  { code: '0184', nameKanji: '宮崎銀行', nameKatakana: 'ミヤザキギンコウ' },
  { code: '0185', nameKanji: '鹿児島銀行', nameKatakana: 'カゴシマギンコウ' },
  { code: '0187', nameKanji: '琉球銀行', nameKatakana: 'リユウキユウギンコウ' },
  { code: '0188', nameKanji: '沖縄銀行', nameKatakana: 'オキナワギンコウ' },
  { code: '0190', nameKanji: '西日本シティ銀行', nameKatakana: 'ニシニツポンシテイギンコウ' },
  { code: '0191', nameKanji: '北九州銀行', nameKatakana: 'キタキユウシユウギンコウ' },
  { code: '0288', nameKanji: '三菱UFJ信託銀行', nameKatakana: 'ミツビシＵＦＪシンタクギンコウ' },
  { code: '0289', nameKanji: 'みずほ信託銀行', nameKatakana: 'ミズホシンタクギンコウ' },
  { code: '0294', nameKanji: '三井住友信託銀行', nameKatakana: 'ミツイスミトモシンタクギンコウ' },
  { code: '0295', nameKanji: 'ニューヨークメロン信託銀行', nameKatakana: 'ニユ－ヨ－クメロンシンタクギンコウ' },
  { code: '0297', nameKanji: '日本マスタートラスト信託銀行', nameKatakana: 'ニホンマスタ－トラストシンタクギンコウ' },
  { code: '0300', nameKanji: 'SMBC信託銀行', nameKatakana: 'エスエムビ－シ－シンタクギンコウ' },
  { code: '0304', nameKanji: '野村信託銀行', nameKatakana: 'ノムラシンタクギンコウ' },
  { code: '0307', nameKanji: 'オリックス銀行', nameKatakana: 'オリツクスギンコウ' },
  { code: '0310', nameKanji: 'GMOあおぞらネット銀行', nameKatakana: 'ジ－エムオ－アオゾラネツトギンコウ' },
  { code: '0311', nameKanji: '農中信託銀行', nameKatakana: 'ノウチユウシンタクギンコウ' },
  { code: '0320', nameKanji: '新生信託銀行', nameKatakana: 'シンセイシンタクギンコウ' },
  { code: '0321', nameKanji: '日証金信託銀行', nameKatakana: 'ニツシヨウキンシンタクギンコウ' },
  { code: '0324', nameKanji: '日本カストディ銀行', nameKatakana: 'ニホンカストデイギンコウ' },
  { code: '0397', nameKanji: 'SBI新生銀行', nameKatakana: 'エスビ－アイシンセイギンコウ' },
  { code: '0398', nameKanji: 'あおぞら銀行', nameKatakana: 'アオゾラギンコウ' },
  { code: '0401', nameKanji: 'シティバンク、エヌ・エイ銀行', nameKatakana: 'シテイバンクギンコウ' },
  { code: '0402', nameKanji: 'JPモルガン銀行', nameKatakana: 'ジエ－ピ－モルガンギンコウ' },
  { code: '0403', nameKanji: 'バンク・オブ・アメリカ・エヌ・エイ銀行', nameKatakana: 'アメリカギンコウ' },
  { code: '0411', nameKanji: '香港上海銀行', nameKatakana: 'ホンコンシヤンハイギンコウ' },
  { code: '0413', nameKanji: 'スタンダード　チャータード銀行', nameKatakana: 'スタンダ－ドチヤ－タ－ドギンコウ' },
  { code: '0414', nameKanji: 'バークレイズ銀行', nameKatakana: 'バ－クレイズギンコウ' },
  { code: '0421', nameKanji: 'クレディ・アグリコル銀行', nameKatakana: 'クレディ・アグリコルギンコウ' },
  { code: '0423', nameKanji: 'ハナ銀行', nameKatakana: 'ハナギンコウ' },
  { code: '0424', nameKanji: '印度銀行', nameKatakana: 'インドギンコウ' },
  { code: '0425', nameKanji: '兆豊國際商業銀行', nameKatakana: 'チヨウホウコクサイシヨウギヨギンコウ' },
  { code: '0426', nameKanji: 'バンコック銀行', nameKatakana: 'バンコツクギンコウ' },
  { code: '0429', nameKanji: 'バンクネガラインドネシア銀行', nameKatakana: 'バンクネガラインドネシアギンコウ' },
  { code: '0430', nameKanji: 'ドイツ銀行', nameKatakana: 'ドイツギンコウ' },
  { code: '0432', nameKanji: 'ブラジル銀行', nameKatakana: 'ブラジルギンコウ' },
  { code: '0438', nameKanji: 'ユナイテッド・オーバーシーズ銀行', nameKatakana: 'ユナイテツド．オ－バ－シ－ギンコウ' },
  { code: '0439', nameKanji: 'ユービーエス・エイ・ジー銀行', nameKatakana: 'ユ－ビ－エスエイジ－ギンコウ' },
  { code: '0442', nameKanji: 'ニューヨークメロン銀行', nameKatakana: 'ニユ－ヨ－クメロンギンコウ' },
  { code: '0443', nameKanji: 'ビー・エヌ・ピー・パリバ銀行', nameKatakana: 'ビ－エヌピ－パリバギンコウ' },
  { code: '0444', nameKanji: 'オーバーシー・チャイニーズ銀行', nameKatakana: 'オ－バ－シ－．チヤイニ－ズギンコウ' },
  { code: '0445', nameKanji: 'ソシエテ　ジェネラル銀行', nameKatakana: 'ソシエテ　ジエネラルギンコウ' },
  { code: '0456', nameKanji: 'ユバフーアラブ・フランス連合銀行', nameKatakana: 'ユバフ－．アラブ．フランスギンコウ' },
  { code: '0458', nameKanji: 'DBS銀行', nameKatakana: 'デイ－ビ－エスギンコウ' },
  { code: '0460', nameKanji: 'クレディ・スイス銀行', nameKatakana: 'クレデイ．スイスギンコウ' },
  { code: '0461', nameKanji: 'コメルツ銀行', nameKatakana: 'コメルツギンコウ' },
  { code: '0463', nameKanji: 'ウニクレディト銀行', nameKatakana: 'ウニクレデイトギンコウ' },
  { code: '0468', nameKanji: 'インドステイト銀行', nameKatakana: 'インドステイトギンコウ' },
  { code: '0471', nameKanji: 'カナダロイヤル銀行', nameKatakana: 'カナダロイヤルギンコウ' },
  { code: '0472', nameKanji: 'SBJ銀行', nameKatakana: 'エスビ－ジエ－ギンコウ' },
  { code: '0477', nameKanji: 'ウリィ銀行', nameKatakana: 'ウリイギンコウ' },
  { code: '0482', nameKanji: 'アイエヌジー　バンク　エヌ・ヴィ銀行', nameKatakana: 'アイエヌジ－　バンク　エヌギンコウ' },
  { code: '0484', nameKanji: 'ナショナル・オーストラリア・バンク・リミテッド銀行', nameKatakana: 'ナシヨナル．オ－ストラリア．ハギンコウ' },
  { code: '0485', nameKanji: 'オーストラリア・ニュージーランド銀行', nameKatakana: 'オ－ストラリア．ニユ－ジ－ラギンコウ' },
  { code: '0487', nameKanji: 'オーストラリア・コモンウェルズ銀行', nameKatakana: 'オ－ストラリア．コモンウエルスギンコウ' },
  { code: '0489', nameKanji: '中國銀行', nameKatakana: 'チユウゴクギンコウ' },
  { code: '0495', nameKanji: 'ステート・ストリート銀行', nameKatakana: 'ステ－ト．ストリ－トギンコウ' },
  { code: '0498', nameKanji: '中小企業銀行', nameKatakana: 'チユウシヨウキギヨウギンコウ' },
  { code: '0501', nameKanji: '北洋銀行', nameKatakana: 'ホクヨウギンコウ' },
  { code: '0508', nameKanji: 'きらやか銀行', nameKatakana: 'キラヤカギンコウ' },
  { code: '0509', nameKanji: '北日本銀行', nameKatakana: 'キタニツポンギンコウ' },
  { code: '0512', nameKanji: '仙台銀行', nameKatakana: 'センダイギンコウ' },
  { code: '0513', nameKanji: '福島銀行', nameKatakana: 'フクシマギンコウ' },
  { code: '0514', nameKanji: '大東銀行', nameKatakana: 'ダイトウギンコウ' },
  { code: '0516', nameKanji: '東和銀行', nameKatakana: 'トウワギンコウ' },
  { code: '0517', nameKanji: '栃木銀行', nameKatakana: 'トチギギンコウ' },
  { code: '0522', nameKanji: '京葉銀行', nameKatakana: 'ケイヨウギンコウ' },
  { code: '0525', nameKanji: '東日本銀行', nameKatakana: 'ヒガシニツポンギンコウ' },
  { code: '0526', nameKanji: '東京スター銀行', nameKatakana: 'トウキヨウスタ－ギンコウ' },
  { code: '0530', nameKanji: '神奈川銀行', nameKatakana: 'カナガワギンコウ' },
  { code: '0532', nameKanji: '大光銀行', nameKatakana: 'タイコウギンコウ' },
  { code: '0533', nameKanji: '長野銀行', nameKatakana: 'ナガノギンコウ' },
  { code: '0534', nameKanji: '富山第一銀行', nameKatakana: 'トヤマダイイチギンコウ' },
  { code: '0537', nameKanji: '福邦銀行', nameKatakana: 'フクホウギンコウ' },
  { code: '0538', nameKanji: '静岡中央銀行', nameKatakana: 'シズオカチユウオウギンコウ' },
  { code: '0542', nameKanji: '愛知銀行', nameKatakana: 'アイチギンコウ' },
  { code: '0543', nameKanji: '名古屋銀行', nameKatakana: 'ナゴヤギンコウ' },
  { code: '0544', nameKanji: '中京銀行', nameKatakana: 'チユウキヨウギンコウ' },
  { code: '0562', nameKanji: 'みなと銀行', nameKatakana: 'ミナトギンコウ' },
  { code: '0565', nameKanji: '島根銀行', nameKatakana: 'シマネギンコウ' },
  { code: '0566', nameKanji: 'トマト銀行', nameKatakana: 'トマトギンコウ' },
  { code: '0569', nameKanji: 'もみじ銀行', nameKatakana: 'モミジギンコウ' },
  { code: '0570', nameKanji: '西京銀行', nameKatakana: 'サイキヨウギンコウ' },
  { code: '0572', nameKanji: '徳島大正銀行', nameKatakana: 'トクシマタイシヨウギンコウ' },
  { code: '0573', nameKanji: '香川銀行', nameKatakana: 'カガワギンコウ' },
  { code: '0576', nameKanji: '愛媛銀行', nameKatakana: 'エヒメギンコウ' },
  { code: '0578', nameKanji: '高知銀行', nameKatakana: 'コウチギンコウ' },
  { code: '0582', nameKanji: '福岡中央銀行', nameKatakana: 'フクオカチユウオウギンコウ' },
  { code: '0583', nameKanji: '佐賀共栄銀行', nameKatakana: 'サガキヨウエイギンコウ' },
  { code: '0585', nameKanji: '長崎銀行', nameKatakana: 'ナガサキギンコウ' },
  { code: '0587', nameKanji: '熊本銀行', nameKatakana: 'クマモトギンコウ' },
  { code: '0590', nameKanji: '豊和銀行', nameKatakana: 'ホウワギンコウ' },
  { code: '0591', nameKanji: '宮崎太陽銀行', nameKatakana: 'ミヤザキタイヨウギンコウ' },
  { code: '0594', nameKanji: '南日本銀行', nameKatakana: 'ミナミニツポンギンコウ' },
  { code: '0596', nameKanji: '沖縄海邦銀行', nameKatakana: 'オキナワカイホウギンコウ' },
  { code: '0603', nameKanji: '韓国産業銀行', nameKatakana: 'カンコクサンギヨウギンコウ' },
  { code: '0607', nameKanji: '彰化商業銀行', nameKatakana: 'シヨウカシヨウギヨウギンコウ' },
  { code: '0608', nameKanji: 'ウェルズ・ファーゴ銀行', nameKatakana: 'ウエルズ．フア－ゴギンコウ' },
  { code: '0611', nameKanji: '第一商業銀行', nameKatakana: 'ダイイチシヨウギヨウギンコウ' },
  { code: '0612', nameKanji: '台湾銀行', nameKatakana: 'タイワンギンコウ' },
  { code: '0615', nameKanji: '交通銀行', nameKatakana: 'コウツウギンコウ' },
  { code: '0616', nameKanji: 'メトロポリタン銀行', nameKatakana: 'メトロポリタンギンコウ' },
  { code: '0617', nameKanji: 'フィリピン・ナショナル・バンク銀行', nameKatakana: 'フイリピン．ナシヨナルギンコウ' },
  { code: '0619', nameKanji: '中国工商銀行', nameKatakana: 'チユウゴクコウシヨウギンコウ' },
  { code: '0621', nameKanji: '中國信託商業銀行', nameKatakana: 'チユウゴクシンタクシヨウギギンコウ' },
  { code: '0623', nameKanji: 'インテーザ・サンパオロ銀行', nameKatakana: 'インテ－ザ．サンパオロギンコウ' },
  { code: '0624', nameKanji: '國民銀行', nameKatakana: 'コクミンギンコウ' },
  { code: '0625', nameKanji: '中国建設銀行', nameKatakana: 'チユウゴクケンセツギンコウ' },
  { code: '0627', nameKanji: 'ビルバオ・ビスカヤ・アルヘンタリア銀行', nameKatakana: 'ビルバオ．ビスカヤ．アルギンコウ' },
  { code: '0630', nameKanji: '中国農業銀行', nameKatakana: 'チユウゴクノウギヨウギンコウ' },
  { code: '0631', nameKanji: '台新國際商業銀行', nameKatakana: 'タイシンコクサイシヨウギヨウギンコウ' },
  { code: '0632', nameKanji: '玉山銀行', nameKatakana: 'イイサンギンコウ' },
  { code: '0633', nameKanji: '台湾中小企業銀行', nameKatakana: 'タイワンチユウシヨウキギヨウギンコウ' },
  { code: '1000', nameKanji: '信金中央金庫', nameKatakana: 'シンキンチユウオウキンコ' },
  { code: '1001', nameKanji: '北海道信用金庫', nameKatakana: 'ホツカイドウシンヨウキンコ' },
  { code: '1003', nameKanji: '室蘭信用金庫', nameKatakana: 'ムロランシンヨウキンコ' },
  { code: '1004', nameKanji: '空知信用金庫', nameKatakana: 'ソラチシンヨウキンコ' },
  { code: '1006', nameKanji: '苫小牧信用金庫', nameKatakana: 'トマコマイシンヨウキンコ' },
  { code: '1008', nameKanji: '北門信用金庫', nameKatakana: 'ホクモンシンヨウキンコ' },
  { code: '1009', nameKanji: '伊達信用金庫', nameKatakana: 'ダテシンヨウキンコ' },
  { code: '1010', nameKanji: '北空知信用金庫', nameKatakana: 'キタソラチシンヨウキンコ' },
  { code: '1011', nameKanji: '日高信用金庫', nameKatakana: 'ヒダカシンヨウキンコ' },
  { code: '1013', nameKanji: '渡島信用金庫', nameKatakana: 'オシマシンヨウキンコ' },
  { code: '1014', nameKanji: '道南うみ街信用金庫', nameKatakana: 'ドウナンウミマチシンヨウキンコ' },
  { code: '1020', nameKanji: '旭川信用金庫', nameKatakana: 'アサヒカワシンヨウキンコ' },
  { code: '1021', nameKanji: '稚内信用金庫', nameKatakana: 'ワツカナイシンヨウキンコ' },
  { code: '1022', nameKanji: '留萌信用金庫', nameKatakana: 'ルモイシンヨウキンコ' },
  { code: '1024', nameKanji: '北星信用金庫', nameKatakana: 'ホクセイシンヨウキンコ' },
  { code: '1026', nameKanji: '帯広信用金庫', nameKatakana: 'オビヒロシンヨウキンコ' },
  { code: '1027', nameKanji: '釧路信用金庫', nameKatakana: 'クシロシンヨウキンコ' },
  { code: '1028', nameKanji: '大地みらい信用金庫', nameKatakana: 'ダイチミライシンヨウキンコ' },
  { code: '1030', nameKanji: '北見信用金庫', nameKatakana: 'キタミシンヨウキンコ' },
  { code: '1031', nameKanji: '網走信用金庫', nameKatakana: 'アバシリシンヨウキンコ' },
  { code: '1033', nameKanji: '遠軽信用金庫', nameKatakana: 'エンガルシンヨウキンコ' },
  { code: '1104', nameKanji: '東奥信用金庫', nameKatakana: 'トウオウシンヨウキンコ' },
  { code: '1105', nameKanji: '青い森信用金庫', nameKatakana: 'アオイモリシンヨウキンコ' },
  { code: '1120', nameKanji: '秋田信用金庫', nameKatakana: 'アキタシンヨウキンコ' },
  { code: '1123', nameKanji: '羽後信用金庫', nameKatakana: 'ウゴシンヨウキンコ' },
  { code: '1140', nameKanji: '山形信用金庫', nameKatakana: 'ヤマガタシンヨウキンコ' },
  { code: '1141', nameKanji: '米沢信用金庫', nameKatakana: 'ヨネザワシンヨウキンコ' },
  { code: '1142', nameKanji: '鶴岡信用金庫', nameKatakana: 'ツルオカシンヨウキンコ' },
  { code: '1143', nameKanji: '新庄信用金庫', nameKatakana: 'シンジヨウシンヨウキンコ' },
  { code: '1150', nameKanji: '盛岡信用金庫', nameKatakana: 'モリオカシンヨウキンコ' },
  { code: '1152', nameKanji: '宮古信用金庫', nameKatakana: 'ミヤコシンヨウキンコ' },
  { code: '1153', nameKanji: '一関信用金庫', nameKatakana: 'イチノセキシンヨウキンコ' },
  { code: '1154', nameKanji: '北上信用金庫', nameKatakana: 'キタカミシンヨウキンコ' },
  { code: '1155', nameKanji: '花巻信用金庫', nameKatakana: 'ハナマキシンヨウキンコ' },
  { code: '1156', nameKanji: '水沢信用金庫', nameKatakana: 'ミズサワシンヨウキンコ' },
  { code: '1170', nameKanji: '杜の都信用金庫', nameKatakana: 'モリノミヤコシンヨウキンコ' },
  { code: '1171', nameKanji: '宮城第一信用金庫', nameKatakana: 'ミヤギダイイチシンヨウキンコ' },
  { code: '1172', nameKanji: '石巻信用金庫', nameKatakana: 'イシノマキシンヨウキンコ' },
  { code: '1174', nameKanji: '仙南信用金庫', nameKatakana: 'センナンシンヨウキンコ' },
  { code: '1175', nameKanji: '気仙沼信用金庫', nameKatakana: 'ケセンヌマシンヨウキンコ' },
  { code: '1181', nameKanji: '会津信用金庫', nameKatakana: 'アイヅシンヨウキンコ' },
  { code: '1182', nameKanji: '郡山信用金庫', nameKatakana: 'コオリヤマシンヨウキンコ' },
  { code: '1184', nameKanji: '白河信用金庫', nameKatakana: 'シラカワシンヨウキンコ' },
  { code: '1185', nameKanji: '須賀川信用金庫', nameKatakana: 'スカガワシンヨウキンコ' },
  { code: '1186', nameKanji: 'ひまわり信用金庫', nameKatakana: 'ヒマワリシンヨウキンコ' },
  { code: '1188', nameKanji: 'あぶくま信用金庫', nameKatakana: 'アブクマシンヨウキンコ' },
  { code: '1189', nameKanji: '二本松信用金庫', nameKatakana: 'ニホンマツシンヨウキンコ' },
  { code: '1190', nameKanji: '福島信用金庫', nameKatakana: 'フクシマシンヨウキンコ' },
  { code: '1203', nameKanji: '高崎信用金庫', nameKatakana: 'タカサキシンヨウキンコ' },
  { code: '1204', nameKanji: '桐生信用金庫', nameKatakana: 'キリユウシンヨウキンコ' },
  { code: '1206', nameKanji: 'アイオー信用金庫', nameKatakana: 'アイオ－シンヨウキンコ' },
  { code: '1208', nameKanji: '利根郡信用金庫', nameKatakana: 'トネグンシンヨウキンコ' },
  { code: '1209', nameKanji: '館林信用金庫', nameKatakana: 'タテバヤシシンヨウキンコ' },
  { code: '1210', nameKanji: '北群馬信用金庫', nameKatakana: 'キタグンマシンヨウキンコ' },
  { code: '1211', nameKanji: 'しののめ信用金庫', nameKatakana: 'シノノメシンヨウキンコ' },
  { code: '1221', nameKanji: '足利小山信用金庫', nameKatakana: 'アシカガオヤマシンヨウキンコ' },
  { code: '1222', nameKanji: '栃木信用金庫', nameKatakana: 'トチギシンヨウキンコ' },
  { code: '1223', nameKanji: '鹿沼相互信用金庫', nameKatakana: 'カヌマソウゴシンヨウキンコ' },
  { code: '1224', nameKanji: '佐野信用金庫', nameKatakana: 'サノシンヨウキンコ' },
  { code: '1225', nameKanji: '大田原信用金庫', nameKatakana: 'オオタワラシンヨウキンコ' },
  { code: '1227', nameKanji: '烏山信用金庫', nameKatakana: 'カラスヤマシンヨウキンコ' },
  { code: '1240', nameKanji: '水戸信用金庫', nameKatakana: 'ミトシンヨウキンコ' },
  { code: '1242', nameKanji: '結城信用金庫', nameKatakana: 'ユウキシンヨウキンコ' },
  { code: '1250', nameKanji: '埼玉縣信用金庫', nameKatakana: 'サイタマケンシンヨウキンコ' },
  { code: '1251', nameKanji: '川口信用金庫', nameKatakana: 'カワグチシンヨウキンコ' },
  { code: '1252', nameKanji: '青木信用金庫', nameKatakana: 'アオキシンヨウキンコ' },
  { code: '1253', nameKanji: '飯能信用金庫', nameKatakana: 'ハンノウシンヨウキンコ' },
  { code: '1260', nameKanji: '千葉信用金庫', nameKatakana: 'チバシンヨウキンコ' },
  { code: '1261', nameKanji: '銚子信用金庫', nameKatakana: 'チヨウシシンヨウキンコ' },
  { code: '1262', nameKanji: '東京ベイ信用金庫', nameKatakana: 'トウキヨウベイシンヨウキンコ' },
  { code: '1264', nameKanji: '館山信用金庫', nameKatakana: 'タテヤマシンヨウキンコ' },
  { code: '1267', nameKanji: '佐原信用金庫', nameKatakana: 'サワラシンヨウキンコ' },
  { code: '1280', nameKanji: '横浜信用金庫', nameKatakana: 'ヨコハマシンヨウキンコ' },
  { code: '1281', nameKanji: 'かながわ信用金庫', nameKatakana: 'カナガワシンヨウキンコ' },
  { code: '1282', nameKanji: '湘南信用金庫', nameKatakana: 'シヨウナンシンヨウキンコ' },
  { code: '1283', nameKanji: '川崎信用金庫', nameKatakana: 'カワサキシンヨウキンコ' },
  { code: '1286', nameKanji: '平塚信用金庫', nameKatakana: 'ヒラツカシンヨウキンコ' },
  { code: '1288', nameKanji: 'さがみ信用金庫', nameKatakana: 'サガミシンヨウキンコ' },
  { code: '1289', nameKanji: '中栄信用金庫', nameKatakana: 'チユウエイシンヨウキンコ' },
  { code: '1290', nameKanji: '中南信用金庫', nameKatakana: 'チユウナンシンヨウキンコ' },
  { code: '1303', nameKanji: '朝日信用金庫', nameKatakana: 'アサヒシンヨウキンコ' },
  { code: '1305', nameKanji: '興産信用金庫', nameKatakana: 'コウサンシンヨウキンコ' },
  { code: '1310', nameKanji: 'さわやか信用金庫', nameKatakana: 'サワヤカシンヨウキンコ' },
  { code: '1311', nameKanji: '東京シティ信用金庫', nameKatakana: 'トウキヨウシテイシンヨウキンコ' },
  { code: '1319', nameKanji: '芝信用金庫', nameKatakana: 'シバシンヨウキンコ' },
  { code: '1320', nameKanji: '東京東信用金庫', nameKatakana: 'トウキヨウヒガシシンヨウキンコ' },
  { code: '1321', nameKanji: '東栄信用金庫', nameKatakana: 'トウエイシンヨウキンコ' },
  { code: '1323', nameKanji: '亀有信用金庫', nameKatakana: 'カメアリシンヨウキンコ' },
  { code: '1326', nameKanji: '小松川信用金庫', nameKatakana: 'コマツガワシンヨウキンコ' },
  { code: '1327', nameKanji: '足立成和信用金庫', nameKatakana: 'アダチセイワシンヨウキンコ' },
  { code: '1333', nameKanji: '東京三協信用金庫', nameKatakana: 'トウキヨウサンキヨウシンヨウキンコ' },
  { code: '1336', nameKanji: '西京信用金庫', nameKatakana: 'サイキヨウシンヨウキンコ' },
  { code: '1341', nameKanji: '西武信用金庫', nameKatakana: 'セイブシンヨウキンコ' },
  { code: '1344', nameKanji: '城南信用金庫', nameKatakana: 'ジヨウナンシンヨウキンコ' },
  { code: '1345', nameKanji: '昭和信用金庫', nameKatakana: 'シヨウワシンヨウキンコ' },
  { code: '1346', nameKanji: '目黒信用金庫', nameKatakana: 'メグロシンヨウキンコ' },
  { code: '1348', nameKanji: '世田谷信用金庫', nameKatakana: 'セタガヤシンヨウキンコ' },
  { code: '1349', nameKanji: '東京信用金庫', nameKatakana: 'トウキヨウシンヨウキンコ' },
  { code: '1351', nameKanji: '城北信用金庫', nameKatakana: 'ジヨウホクシンヨウキンコ' },
  { code: '1352', nameKanji: '瀧野川信用金庫', nameKatakana: 'タキノガワシンヨウキンコ' },
  { code: '1356', nameKanji: '巣鴨信用金庫', nameKatakana: 'スガモシンヨウキンコ' },
  { code: '1358', nameKanji: '青梅信用金庫', nameKatakana: 'オウメシンヨウキンコ' },
  { code: '1360', nameKanji: '多摩信用金庫', nameKatakana: 'タマシンヨウキンコ' },
  { code: '1370', nameKanji: '新潟信用金庫', nameKatakana: 'ニイガタシンヨウキンコ' },
  { code: '1371', nameKanji: '長岡信用金庫', nameKatakana: 'ナガオカシンヨウキンコ' },
  { code: '1373', nameKanji: '三条信用金庫', nameKatakana: 'サンジヨウシンヨウキンコ' },
  { code: '1374', nameKanji: '新発田信用金庫', nameKatakana: 'シバタシンヨウキンコ' },
  { code: '1375', nameKanji: '柏崎信用金庫', nameKatakana: 'カシワザキシンヨウキンコ' },
  { code: '1376', nameKanji: '上越信用金庫', nameKatakana: 'ジヨウエツシンヨウキンコ' },
  { code: '1377', nameKanji: '新井信用金庫', nameKatakana: 'アライシンヨウキンコ' },
  { code: '1379', nameKanji: '村上信用金庫', nameKatakana: 'ムラカミシンヨウキンコ' },
  { code: '1380', nameKanji: '加茂信用金庫', nameKatakana: 'カモシンヨウキンコ' },
  { code: '1385', nameKanji: '甲府信用金庫', nameKatakana: 'コウフシンヨウキンコ' },
  { code: '1386', nameKanji: '山梨信用金庫', nameKatakana: 'ヤマナシシンヨウキンコ' },
  { code: '1390', nameKanji: '長野信用金庫', nameKatakana: 'ナガノシンヨウキンコ' },
  { code: '1391', nameKanji: '松本信用金庫', nameKatakana: 'マツモトシンヨウキンコ' },
  { code: '1392', nameKanji: '上田信用金庫', nameKatakana: 'ウエダシンヨウキンコ' },
  { code: '1393', nameKanji: '諏訪信用金庫', nameKatakana: 'スワシンヨウキンコ' },
  { code: '1394', nameKanji: '飯田信用金庫', nameKatakana: 'イイダシンヨウキンコ' },
  { code: '1396', nameKanji: 'アルプス中央信用金庫', nameKatakana: 'アルプスチユウオウシンヨウキンコ' },
  { code: '1401', nameKanji: '富山信用金庫', nameKatakana: 'トヤマシンヨウキンコ' },
  { code: '1402', nameKanji: '高岡信用金庫', nameKatakana: 'タカオカシンヨウキンコ' },
  { code: '1404', nameKanji: '新湊信用金庫', nameKatakana: 'シンミナトシンヨウキンコ' },
  { code: '1405', nameKanji: 'にいかわ信用金庫', nameKatakana: 'ニイカワシンヨウキンコ' },
  { code: '1406', nameKanji: '氷見伏木信用金庫', nameKatakana: 'ヒミフシキシンヨウキンコ' },
  { code: '1412', nameKanji: '砺波信用金庫', nameKatakana: 'トナミシンヨウキンコ' },
  { code: '1413', nameKanji: '石動信用金庫', nameKatakana: 'イスルギシンヨウキンコ' },
  { code: '1440', nameKanji: '金沢信用金庫', nameKatakana: 'カナザワシンヨウキンコ' },
  { code: '1442', nameKanji: 'のと共栄信用金庫', nameKatakana: 'ノトキヨウエイシンヨウキンコ' },
  { code: '1444', nameKanji: 'はくさん信用金庫', nameKatakana: 'ハクサンシンヨウキンコ' },
  { code: '1448', nameKanji: '興能信用金庫', nameKatakana: 'コウノウシンヨウキンコ' },
  { code: '1470', nameKanji: '福井信用金庫', nameKatakana: 'フクイシンヨウキンコ' },
  { code: '1471', nameKanji: '敦賀信用金庫', nameKatakana: 'ツルガシンヨウキンコ' },
  { code: '1473', nameKanji: '小浜信用金庫', nameKatakana: 'オバマシンヨウキンコ' },
  { code: '1475', nameKanji: '越前信用金庫', nameKatakana: 'エチゼンシンヨウキンコ' },
  { code: '1501', nameKanji: 'しずおか焼津信用金庫', nameKatakana: 'シズオカヤイヅシンヨウキンコ' },
  { code: '1502', nameKanji: '静清信用金庫', nameKatakana: 'セイシンシンヨウキンコ' },
  { code: '1503', nameKanji: '浜松磐田信用金庫', nameKatakana: 'ハママツイワタシンヨウキンコ' },
  { code: '1505', nameKanji: '沼津信用金庫', nameKatakana: 'ヌマヅシンヨウキンコ' },
  { code: '1506', nameKanji: '三島信用金庫', nameKatakana: 'ミシマシンヨウキンコ' },
  { code: '1507', nameKanji: '富士宮信用金庫', nameKatakana: 'フジノミヤシンヨウキンコ' },
  { code: '1513', nameKanji: '島田掛川信用金庫', nameKatakana: 'シマダカケガワシンヨウキンコ' },
  { code: '1515', nameKanji: '富士信用金庫', nameKatakana: 'フジシンヨウキンコ' },
  { code: '1517', nameKanji: '遠州信用金庫', nameKatakana: 'エンシユウシンヨウキンコ' },
  { code: '1530', nameKanji: '岐阜信用金庫', nameKatakana: 'ギフシンヨウキンコ' },
  { code: '1531', nameKanji: '大垣西濃信用金庫', nameKatakana: 'オオガキセイノウシンヨウキンコ' },
  { code: '1532', nameKanji: '高山信用金庫', nameKatakana: 'タカヤマシンヨウキンコ' },
  { code: '1533', nameKanji: '東濃信用金庫', nameKatakana: 'トウノウシンヨウキンコ' },
  { code: '1534', nameKanji: '関信用金庫', nameKatakana: 'セキシンヨウキンコ' },
  { code: '1538', nameKanji: '八幡信用金庫', nameKatakana: 'ハチマンシンヨウキンコ' },
  { code: '1550', nameKanji: '愛知信用金庫', nameKatakana: 'アイチシンヨウキンコ' },
  { code: '1551', nameKanji: '豊橋信用金庫', nameKatakana: 'トヨハシシンヨウキンコ' },
  { code: '1552', nameKanji: '岡崎信用金庫', nameKatakana: 'オカザキシンヨウキンコ' },
  { code: '1553', nameKanji: 'いちい信用金庫', nameKatakana: 'イチイシンヨウキンコ' },
  { code: '1554', nameKanji: '瀬戸信用金庫', nameKatakana: 'セトシンヨウキンコ' },
  { code: '1555', nameKanji: '半田信用金庫', nameKatakana: 'ハンダシンヨウキンコ' },
  { code: '1556', nameKanji: '知多信用金庫', nameKatakana: 'チタシンヨウキンコ' },
  { code: '1557', nameKanji: '豊川信用金庫', nameKatakana: 'トヨカワシンヨウキンコ' },
  { code: '1559', nameKanji: '豊田信用金庫', nameKatakana: 'トヨタシンヨウキンコ' },
  { code: '1560', nameKanji: '碧海信用金庫', nameKatakana: 'ヘキカイシンヨウキンコ' },
  { code: '1561', nameKanji: '西尾信用金庫', nameKatakana: 'ニシオシンヨウキンコ' },
  { code: '1562', nameKanji: '蒲郡信用金庫', nameKatakana: 'ガマゴオリシンヨウキンコ' },
  { code: '1563', nameKanji: '尾西信用金庫', nameKatakana: 'ビサイシンヨウキンコ' },
  { code: '1565', nameKanji: '中日信用金庫', nameKatakana: 'チユウニチシンヨウキンコ' },
  { code: '1566', nameKanji: '東春信用金庫', nameKatakana: 'トウシユンシンヨウキンコ' },
  { code: '1580', nameKanji: '津信用金庫', nameKatakana: 'ツシンヨウキンコ' },
  { code: '1581', nameKanji: '北伊勢上野信用金庫', nameKatakana: 'キタイセウエノシンヨウキンコ' },
  { code: '1583', nameKanji: '桑名三重信用金庫', nameKatakana: 'クワナミエシンヨウキンコ' },
  { code: '1585', nameKanji: '紀北信用金庫', nameKatakana: 'キホクシンヨウキンコ' },
  { code: '1602', nameKanji: '滋賀中央信用金庫', nameKatakana: 'シガチユウオウシンヨウキンコ' },
  { code: '1603', nameKanji: '長浜信用金庫', nameKatakana: 'ナガハマシンヨウキンコ' },
  { code: '1604', nameKanji: '湖東信用金庫', nameKatakana: 'コトウシンヨウキンコ' },
  { code: '1610', nameKanji: '京都信用金庫', nameKatakana: 'キヨウトシンヨウキンコ' },
  { code: '1611', nameKanji: '京都中央信用金庫', nameKatakana: 'キヨウトチユウオウシンヨウキンコ' },
  { code: '1620', nameKanji: '京都北都信用金庫', nameKatakana: 'キヨウトホクトシンヨウキンコ' },
  { code: '1630', nameKanji: '大阪信用金庫', nameKatakana: 'オオサカシンヨウキンコ' },
  { code: '1633', nameKanji: '大阪厚生信用金庫', nameKatakana: 'オオサカコウセイシンヨウキンコ' },
  { code: '1635', nameKanji: '大阪シティ信用金庫', nameKatakana: 'オオサカシテイシンヨウキンコ' },
  { code: '1636', nameKanji: '大阪商工信用金庫', nameKatakana: 'オオサカシヨウコウシンヨウキンコ' },
  { code: '1643', nameKanji: '永和信用金庫', nameKatakana: 'エイワシンヨウキンコ' },
  { code: '1645', nameKanji: '北おおさか信用金庫', nameKatakana: 'キタオオサカシンヨウキンコ' },
  { code: '1656', nameKanji: '枚方信用金庫', nameKatakana: 'ヒラカタシンヨウキンコ' },
  { code: '1666', nameKanji: '奈良信用金庫', nameKatakana: 'ナラシンヨウキンコ' },
  { code: '1667', nameKanji: '大和信用金庫', nameKatakana: 'ヤマトシンヨウキンコ' },
  { code: '1668', nameKanji: '奈良中央信用金庫', nameKatakana: 'ナラチユウオウシンヨウキンコ' },
  { code: '1671', nameKanji: '新宮信用金庫', nameKatakana: 'シングウシンヨウキンコ' },
  { code: '1674', nameKanji: 'きのくに信用金庫', nameKatakana: 'キノクニシンヨウキンコ' },
  { code: '1680', nameKanji: '神戸信用金庫', nameKatakana: 'コウベシンヨウキンコ' },
  { code: '1685', nameKanji: '姫路信用金庫', nameKatakana: 'ヒメジシンヨウキンコ' },
  { code: '1686', nameKanji: '播州信用金庫', nameKatakana: 'バンシユウシンヨウキンコ' },
  { code: '1687', nameKanji: '兵庫信用金庫', nameKatakana: 'ヒヨウゴシンヨウキンコ' },
  { code: '1688', nameKanji: '尼崎信用金庫', nameKatakana: 'アマガサキシンヨウキンコ' },
  { code: '1689', nameKanji: '日新信用金庫', nameKatakana: 'ニツシンシンヨウキンコ' },
  { code: '1691', nameKanji: '淡路信用金庫', nameKatakana: 'アワジシンヨウキンコ' },
  { code: '1692', nameKanji: '但馬信用金庫', nameKatakana: 'タジマシンヨウキンコ' },
  { code: '1694', nameKanji: '西兵庫信用金庫', nameKatakana: 'ニシヒヨウゴシンヨウキンコ' },
  { code: '1695', nameKanji: '中兵庫信用金庫', nameKatakana: 'ナカヒヨウゴシンヨウキンコ' },
  { code: '1696', nameKanji: '但陽信用金庫', nameKatakana: 'タンヨウシンヨウキンコ' },
  { code: '1701', nameKanji: '鳥取信用金庫', nameKatakana: 'トツトリシンヨウキンコ' },
  { code: '1702', nameKanji: '米子信用金庫', nameKatakana: 'ヨナゴシンヨウキンコ' },
  { code: '1703', nameKanji: '倉吉信用金庫', nameKatakana: 'クラヨシシンヨウキンコ' },
  { code: '1710', nameKanji: 'しまね信用金庫', nameKatakana: 'シマネシンヨウキンコ' },
  { code: '1711', nameKanji: '日本海信用金庫', nameKatakana: 'ニホンカイシンヨウキンコ' },
  { code: '1712', nameKanji: '島根中央信用金庫', nameKatakana: 'シマネチユウオウシンヨウキンコ' },
  { code: '1732', nameKanji: 'おかやま信用金庫', nameKatakana: 'オカヤマシンヨウキンコ' },
  { code: '1734', nameKanji: '水島信用金庫', nameKatakana: 'ミズシマシンヨウキンコ' },
  { code: '1735', nameKanji: '津山信用金庫', nameKatakana: 'ツヤマシンヨウキンコ' },
  { code: '1738', nameKanji: '玉島信用金庫', nameKatakana: 'タマシマシンヨウキンコ' },
  { code: '1740', nameKanji: '備北信用金庫', nameKatakana: 'ビホクシンヨウキンコ' },
  { code: '1741', nameKanji: '吉備信用金庫', nameKatakana: 'キビシンヨウキンコ' },
  { code: '1743', nameKanji: '備前日生信用金庫', nameKatakana: 'ビゼンヒナセシンヨウキンコ' },
  { code: '1750', nameKanji: '広島信用金庫', nameKatakana: 'ヒロシマシンヨウキンコ' },
  { code: '1752', nameKanji: '呉信用金庫', nameKatakana: 'クレシンヨウキンコ' },
  { code: '1756', nameKanji: 'しまなみ信用金庫', nameKatakana: 'シマナミシンヨウキンコ' },
  { code: '1758', nameKanji: '広島みどり信用金庫', nameKatakana: 'ヒロシマミドリシンヨウキンコ' },
  { code: '1780', nameKanji: '萩山口信用金庫', nameKatakana: 'ハギヤマグチシンヨウキンコ' },
  { code: '1781', nameKanji: '西中国信用金庫', nameKatakana: 'ニシチユウゴクシンヨウキンコ' },
  { code: '1789', nameKanji: '東山口信用金庫', nameKatakana: 'ヒガシヤマグチシンヨウキンコ' },
  { code: '1801', nameKanji: '徳島信用金庫', nameKatakana: 'トクシマシンヨウキンコ' },
  { code: '1803', nameKanji: '阿南信用金庫', nameKatakana: 'アナンシンヨウキンコ' },
  { code: '1830', nameKanji: '高松信用金庫', nameKatakana: 'タカマツシンヨウキンコ' },
  { code: '1833', nameKanji: '観音寺信用金庫', nameKatakana: 'カンオンジシンヨウキンコ' },
  { code: '1860', nameKanji: '愛媛信用金庫', nameKatakana: 'エヒメシンヨウキンコ' },
  { code: '1862', nameKanji: '宇和島信用金庫', nameKatakana: 'ウワジマシンヨウキンコ' },
  { code: '1864', nameKanji: '東予信用金庫', nameKatakana: 'トウヨシンヨウキンコ' },
  { code: '1866', nameKanji: '川之江信用金庫', nameKatakana: 'カワノエシンヨウキンコ' },
  { code: '1880', nameKanji: '幡多信用金庫', nameKatakana: 'ハタシンヨウキンコ' },
  { code: '1881', nameKanji: '高知信用金庫', nameKatakana: 'コウチシンヨウキンコ' },
  { code: '1901', nameKanji: '福岡信用金庫', nameKatakana: 'フクオカシンヨウキンコ' },
  { code: '1903', nameKanji: '福岡ひびき信用金庫', nameKatakana: 'フクオカヒビキシンヨウキンコ' },
  { code: '1908', nameKanji: '大牟田柳川信用金庫', nameKatakana: 'オオムタヤナガワシンヨウキンコ' },
  { code: '1909', nameKanji: '筑後信用金庫', nameKatakana: 'チクゴシンヨウキンコ' },
  { code: '1910', nameKanji: '飯塚信用金庫', nameKatakana: 'イイヅカシンヨウキンコ' },
  { code: '1913', nameKanji: '田川信用金庫', nameKatakana: 'タガワシンヨウキンコ' },
  { code: '1917', nameKanji: '大川信用金庫', nameKatakana: 'オオカワシンヨウキンコ' },
  { code: '1920', nameKanji: '遠賀信用金庫', nameKatakana: 'オンガシンヨウキンコ' },
  { code: '1930', nameKanji: '唐津信用金庫', nameKatakana: 'カラツシンヨウキンコ' },
  { code: '1931', nameKanji: '佐賀信用金庫', nameKatakana: 'サガシンヨウキンコ' },
  { code: '1932', nameKanji: '伊万里信用金庫', nameKatakana: 'イマリシンヨウキンコ' },
  { code: '1933', nameKanji: '九州ひぜん信用金庫', nameKatakana: 'キユウシユウヒゼンシンヨウキンコ' },
  { code: '1942', nameKanji: 'たちばな信用金庫', nameKatakana: 'タチバナシンヨウキンコ' },
  { code: '1951', nameKanji: '熊本信用金庫', nameKatakana: 'クマモトシンヨウキンコ' },
  { code: '1952', nameKanji: '熊本第一信用金庫', nameKatakana: 'クマモトダイイチシンヨウキンコ' },
  { code: '1954', nameKanji: '熊本中央信用金庫', nameKatakana: 'クマモトチユウオウシンヨウキンコ' },
  { code: '1955', nameKanji: '天草信用金庫', nameKatakana: 'アマクサシンヨウキンコ' },
  { code: '1960', nameKanji: '大分信用金庫', nameKatakana: 'オオイタシンヨウキンコ' },
  { code: '1962', nameKanji: '大分みらい信用金庫', nameKatakana: 'オオイタミライシンヨウキンコ' },
  { code: '1968', nameKanji: '日田信用金庫', nameKatakana: 'ヒタシンヨウキンコ' },
  { code: '1980', nameKanji: '宮崎第一信用金庫', nameKatakana: 'ミヤザキダイイチシンヨウキンコ' },
  { code: '1982', nameKanji: '延岡信用金庫', nameKatakana: 'ノベオカシンヨウキンコ' },
  { code: '1985', nameKanji: '高鍋信用金庫', nameKatakana: 'タカナベシンヨウキンコ' },
  { code: '1990', nameKanji: '鹿児島信用金庫', nameKatakana: 'カゴシマシンヨウキンコ' },
  { code: '1991', nameKanji: '鹿児島相互信用金庫', nameKatakana: 'カゴシマソウゴシンヨウキンコ' },
  { code: '1993', nameKanji: '奄美大島信用金庫', nameKatakana: 'アマミオオシマシンヨウキンコ' },
  { code: '1996', nameKanji: 'コザ信用金庫', nameKatakana: 'コザシンヨウキンコ' },
  { code: '2004', nameKanji: '商工中金', nameKatakana: 'シヨウコウチユウキン' },
  { code: '2010', nameKanji: '全信組連', nameKatakana: 'ゼンシンクミレン' },
  { code: '2011', nameKanji: '北央信用組合', nameKatakana: 'ホクオウシンヨウクミアイ' },
  { code: '2013', nameKanji: '札幌中央信用組合', nameKatakana: 'サツポロチユウオウシンヨウクミアイ' },
  { code: '2014', nameKanji: 'ウリ信用組合', nameKatakana: 'ウリシンヨウクミアイ' },
  { code: '2017', nameKanji: '函館商工信用組合', nameKatakana: 'ハコダテシヨウコウシンヨウクミアイ' },
  { code: '2019', nameKanji: '空知商工信用組合', nameKatakana: 'ソラチシヨウコウシンヨウクミアイ' },
  { code: '2024', nameKanji: '十勝信用組合', nameKatakana: 'トカチシンヨウクミアイ' },
  { code: '2025', nameKanji: '釧路信用組合', nameKatakana: 'クシロシンヨウクミアイ' },
  { code: '2030', nameKanji: '青森県信用組合', nameKatakana: 'アオモリケンシンヨウクミアイ' },
  { code: '2045', nameKanji: '杜陵信用組合', nameKatakana: 'トリヨウシンヨウクミアイ' },
  { code: '2049', nameKanji: '岩手県医師信用組合', nameKatakana: 'イワテケンイシシンヨウクミアイ' },
  { code: '2060', nameKanji: 'あすか信用組合', nameKatakana: 'アスカシンヨウクミアイ' },
  { code: '2061', nameKanji: '石巻商工信用組合', nameKatakana: 'イシノマキシヨウコウシンヨウクミアイ' },
  { code: '2062', nameKanji: '古川信用組合', nameKatakana: 'フルカワシンヨウクミアイ' },
  { code: '2063', nameKanji: '仙北信用組合', nameKatakana: 'センポクシンヨウクミアイ' },
  { code: '2075', nameKanji: '秋田県信用組合', nameKatakana: 'アキタケンシンヨウクミアイ' },
  { code: '2083', nameKanji: '北郡信用組合', nameKatakana: 'キタグンシンヨウクミアイ' },
  { code: '2084', nameKanji: '山形中央信用組合', nameKatakana: 'ヤマガタチユウオウシンヨウクミアイ' },
  { code: '2085', nameKanji: '山形第一信用組合', nameKatakana: 'ヤマガタダイイチシンヨウクミアイ' },
  { code: '2087', nameKanji: '山形県医師信用組合', nameKatakana: 'ヤマガタケンイシシンヨウクミアイ' },
  { code: '2090', nameKanji: '福島県商工信用組合', nameKatakana: 'フクシマケンシヨウコウシンヨウクミアイ' },
  { code: '2092', nameKanji: 'いわき信用組合', nameKatakana: 'イワキシンヨウクミアイ' },
  { code: '2095', nameKanji: '相双五城信用組合', nameKatakana: 'ソウソウゴジヨウシンヨウクミアイ' },
  { code: '2096', nameKanji: '会津商工信用組合', nameKatakana: 'アイヅシヨウコウシンヨウクミアイ' },
  { code: '2101', nameKanji: '茨城県信用組合', nameKatakana: 'イバラキケンシンヨウクミアイ' },
  { code: '2122', nameKanji: '真岡信用組合', nameKatakana: 'モオカシンヨウクミアイ' },
  { code: '2125', nameKanji: '那須信用組合', nameKatakana: 'ナスシンヨウクミアイ' },
  { code: '2143', nameKanji: 'あかぎ信用組合', nameKatakana: 'アカギシンヨウクミアイ' },
  { code: '2146', nameKanji: '群馬県信用組合', nameKatakana: 'グンマケンシンヨウクミアイ' },
  { code: '2149', nameKanji: 'ぐんまみらい信用組合', nameKatakana: 'グンマミライシンヨウクミアイ' },
  { code: '2151', nameKanji: '群馬県医師信用組合', nameKatakana: 'グンマケンイシシンヨウクミアイ' },
  { code: '2162', nameKanji: '埼玉県医師信用組合', nameKatakana: 'サイタマケンイシシンヨウクミアイ' },
  { code: '2165', nameKanji: '熊谷商工信用組合', nameKatakana: 'クマガヤシヨウコウシンヨウクミアイ' },
  { code: '2167', nameKanji: '埼玉信用組合', nameKatakana: 'サイタマシンヨウクミアイ' },
  { code: '2180', nameKanji: '房総信用組合', nameKatakana: 'ボウソウシンヨウクミアイ' },
  { code: '2184', nameKanji: '銚子商工信用組合', nameKatakana: 'チヨウシシヨウコウシンヨウクミアイ' },
  { code: '2190', nameKanji: '君津信用組合', nameKatakana: 'キミツシンヨウクミアイ' },
  { code: '2202', nameKanji: '全東栄信用組合', nameKatakana: 'ゼントウエイシンヨウクミアイ' },
  { code: '2210', nameKanji: '東浴信用組合', nameKatakana: 'トウヨクシンヨウクミアイ' },
  { code: '2211', nameKanji: '文化産業信用組合', nameKatakana: 'ブンカサンギヨウシンヨウクミアイ' },
  { code: '2213', nameKanji: '整理回収機構', nameKatakana: 'セイリカイシユウキコウ' },
  { code: '2215', nameKanji: '東京証券信用組合', nameKatakana: 'トウキヨウシヨウケンシンヨウクミアイ' },
  { code: '2224', nameKanji: '東京厚生信用組合', nameKatakana: 'トウキヨウコウセイシンヨウクミアイ' },
  { code: '2226', nameKanji: '東信用組合', nameKatakana: 'アヅマシンヨウクミアイ' },
  { code: '2229', nameKanji: '江東信用組合', nameKatakana: 'コウトウシンヨウクミアイ' },
  { code: '2231', nameKanji: '青和信用組合', nameKatakana: 'セイワシンヨウクミアイ' },
  { code: '2235', nameKanji: '中ノ郷信用組合', nameKatakana: 'ナカノゴウシンヨウクミアイ' },
  { code: '2241', nameKanji: '共立信用組合', nameKatakana: 'キヨウリツシンヨウクミアイ' },
  { code: '2243', nameKanji: '七島信用組合', nameKatakana: 'シチトウシンヨウクミアイ' },
  { code: '2248', nameKanji: '大東京信用組合', nameKatakana: 'ダイトウキヨウシンヨウクミアイ' },
  { code: '2254', nameKanji: '第一勧業信用組合', nameKatakana: 'ダイイチカンギヨウシンヨウクミアイ' },
  { code: '2271', nameKanji: '警視庁職員信用組合', nameKatakana: 'ケイシチヨウシヨクインシンヨウクミアイ' },
  { code: '2274', nameKanji: '東京消防信用組合', nameKatakana: 'トウキヨウシヨウボウシンヨウクミアイ' },
  { code: '2276', nameKanji: '東京都職員信用組合', nameKatakana: 'トウキヨウトシヨクインシンヨウクミアイ' },
  { code: '2277', nameKanji: 'ハナ信用組合', nameKatakana: 'ハナシンヨウクミアイ' },
  { code: '2304', nameKanji: '神奈川県医師信用組合', nameKatakana: 'カナガワケンイシシンヨウクミアイ' },
  { code: '2305', nameKanji: '神奈川県歯科医師信用組合', nameKatakana: 'カナガワケンシカイシシンヨウクミアイ' },
  { code: '2306', nameKanji: '横浜幸銀信用組合', nameKatakana: 'ヨコハマコウギンシンヨウクミアイ' },
  { code: '2307', nameKanji: '横浜華銀信用組合', nameKatakana: 'ヨコハマカギンシンヨウクミアイ' },
  { code: '2315', nameKanji: '小田原第一信用組合', nameKatakana: 'オダワラダイイチシンヨウクミアイ' },
  { code: '2318', nameKanji: '相愛信用組合', nameKatakana: 'ソウアイシンヨウクミアイ' },
  { code: '2332', nameKanji: '静岡県医師信用組合', nameKatakana: 'シズオカケンイシシンヨウクミアイ' },
  { code: '2351', nameKanji: '新潟縣信用組合', nameKatakana: 'ニイガタケンシンヨウクミアイ' },
  { code: '2356', nameKanji: '興栄信用組合', nameKatakana: 'コウエイシンヨウクミアイ' },
  { code: '2357', nameKanji: 'はばたき信用組合', nameKatakana: 'ハバタキシンヨウクミアイ' },
  { code: '2360', nameKanji: '協栄信用組合', nameKatakana: 'キヨウエイシンヨウクミアイ' },
  { code: '2362', nameKanji: '巻信用組合', nameKatakana: 'マキシンヨウクミアイ' },
  { code: '2363', nameKanji: '新潟大栄信用組合', nameKatakana: 'ニイガタダイエイシンヨウクミアイ' },
  { code: '2365', nameKanji: 'ゆきぐに信用組合', nameKatakana: 'ユキグニシンヨウクミアイ' },
  { code: '2366', nameKanji: '糸魚川信用組合', nameKatakana: 'イトイガワシンヨウクミアイ' },
  { code: '2377', nameKanji: '山梨県民信用組合', nameKatakana: 'ヤマナシケンミンシンヨウクミアイ' },
  { code: '2378', nameKanji: '都留信用組合', nameKatakana: 'ツルシンヨウクミアイ' },
  { code: '2390', nameKanji: '長野県信用組合', nameKatakana: 'ナガノケンシンヨウクミアイ' },
  { code: '2402', nameKanji: '富山県医師信用組合', nameKatakana: 'トヤマケンイシシンヨウクミアイ' },
  { code: '2404', nameKanji: '富山県信用組合', nameKatakana: 'トヤマケンシンヨウクミアイ' },
  { code: '2411', nameKanji: '金沢中央信用組合', nameKatakana: 'カナザワチユウオウシンヨウクミアイ' },
  { code: '2417', nameKanji: '石川県医師信用組合', nameKatakana: 'イシカワケンイシシンヨウクミアイ' },
  { code: '2430', nameKanji: '福泉信用組合', nameKatakana: 'フクセンシンヨウクミアイ' },
  { code: '2435', nameKanji: '福井県医師信用組合', nameKatakana: 'フクイケンイシシンヨウクミアイ' },
  { code: '2440', nameKanji: '丸八信用組合', nameKatakana: 'マルハチシンヨウクミアイ' },
  { code: '2442', nameKanji: '愛知商銀信用組合', nameKatakana: 'アイチシヨウギンシンヨウクミアイ' },
  { code: '2443', nameKanji: '愛知県警察信用組合', nameKatakana: 'アイチケンケイサツシンヨウクミアイ' },
  { code: '2444', nameKanji: '名古屋青果物信用組合', nameKatakana: 'ナゴヤセイカブツシンヨウクミアイ' },
  { code: '2446', nameKanji: '愛知県医療信用組合', nameKatakana: 'アイチケンイリヨウシンヨウクミアイ' },
  { code: '2447', nameKanji: '愛知県医師信用組合', nameKatakana: 'アイチケンイシシンヨウクミアイ' },
  { code: '2448', nameKanji: '豊橋商工信用組合', nameKatakana: 'トヨハシシヨウコウシンヨウクミアイ' },
  { code: '2451', nameKanji: '愛知県中央信用組合', nameKatakana: 'アイチケンチユウオウシンヨウクミアイ' },
  { code: '2470', nameKanji: '岐阜商工信用組合', nameKatakana: 'ギフシヨウコウシンヨウクミアイ' },
  { code: '2471', nameKanji: 'イオ信用組合', nameKatakana: 'イオシンヨウクミアイ' },
  { code: '2473', nameKanji: '岐阜県医師信用組合', nameKatakana: 'ギフケンイシシンヨウクミアイ' },
  { code: '2476', nameKanji: '飛騨信用組合', nameKatakana: 'ヒダシンヨウクミアイ' },
  { code: '2481', nameKanji: '益田信用組合', nameKatakana: 'マシタシンヨウクミアイ' },
  { code: '2485', nameKanji: '三重県職員信用組合', nameKatakana: 'ミエケンシヨクインシンヨウクミアイ' },
  { code: '2504', nameKanji: '滋賀県民信用組合', nameKatakana: 'シガケンミンシンヨウクミアイ' },
  { code: '2505', nameKanji: '滋賀県信用組合', nameKatakana: 'シガケンシンヨウクミアイ' },
  { code: '2526', nameKanji: '京滋信用組合', nameKatakana: 'ケイジシンヨウクミアイ' },
  { code: '2540', nameKanji: '大同信用組合', nameKatakana: 'ダイドウシンヨウクミアイ' },
  { code: '2541', nameKanji: '成協信用組合', nameKatakana: 'セイキヨウシンヨウクミアイ' },
  { code: '2543', nameKanji: '大阪協栄信用組合', nameKatakana: 'オオサカキヨウエイシンヨウクミアイ' },
  { code: '2548', nameKanji: '大阪貯蓄信用組合', nameKatakana: 'オオサカチヨチクシンヨウクミアイ' },
  { code: '2549', nameKanji: 'のぞみ信用組合', nameKatakana: 'ノゾミシンヨウクミアイ' },
  { code: '2556', nameKanji: '中央信用組合', nameKatakana: 'チユウオウシンヨウクミアイ' },
  { code: '2560', nameKanji: '大阪府医師信用組合', nameKatakana: 'オオサカフイシシンヨウクミアイ' },
  { code: '2566', nameKanji: '大阪府警察信用組合', nameKatakana: 'オオサカフケイサツシンヨウクミアイ' },
  { code: '2567', nameKanji: '近畿産業信用組合', nameKatakana: 'キンキサンギヨウシンヨウクミアイ' },
  { code: '2580', nameKanji: '朝日新聞信用組合', nameKatakana: 'アサヒシンブンシンヨウクミアイ' },
  { code: '2581', nameKanji: '毎日信用組合', nameKatakana: 'マイニチシンヨウクミアイ' },
  { code: '2582', nameKanji: 'ミレ信用組合', nameKatakana: 'ミレシンヨウクミアイ' },
  { code: '2602', nameKanji: '兵庫県警察信用組合', nameKatakana: 'ヒヨウゴケンケイサツシンヨウクミアイ' },
  { code: '2605', nameKanji: '兵庫県医療信用組合', nameKatakana: 'ヒヨウゴケンイリヨウシンヨウクミアイ' },
  { code: '2606', nameKanji: '兵庫県信用組合', nameKatakana: 'ヒヨウゴケンシンヨウクミアイ' },
  { code: '2610', nameKanji: '神戸市職員信用組合', nameKatakana: 'コウベシシヨクインシンヨウクミアイ' },
  { code: '2616', nameKanji: '淡陽信用組合', nameKatakana: 'ダンヨウシンヨウクミアイ' },
  { code: '2620', nameKanji: '兵庫ひまわり信用組合', nameKatakana: 'ヒヨウゴヒマワリシンヨウクミアイ' },
  { code: '2634', nameKanji: '和歌山県医師信用組合', nameKatakana: 'ワカヤマケンイシシンヨウクミアイ' },
  { code: '2661', nameKanji: '島根益田信用組合', nameKatakana: 'シマネマスダシンヨウクミアイ' },
  { code: '2672', nameKanji: '朝銀西信用組合', nameKatakana: 'チヨウギンニシシンヨウクミアイ' },
  { code: '2674', nameKanji: '笠岡信用組合', nameKatakana: 'カサオカシンヨウクミアイ' },
  { code: '2680', nameKanji: '広島市信用組合', nameKatakana: 'ヒロシマシシンヨウクミアイ' },
  { code: '2681', nameKanji: '広島県信用組合', nameKatakana: 'ヒロシマケンシンヨウクミアイ' },
  { code: '2684', nameKanji: '広島商銀信用組合', nameKatakana: 'ヒロシマシヨウギンシンヨウクミアイ' },
  { code: '2686', nameKanji: '呉市職員信用組合', nameKatakana: 'クレシシヨクインシンヨウクミアイ' },
  { code: '2690', nameKanji: '両備信用組合', nameKatakana: 'リヨウビシンヨウクミアイ' },
  { code: '2696', nameKanji: '備後信用組合', nameKatakana: 'ビンゴシンヨウクミアイ' },
  { code: '2703', nameKanji: '山口県信用組合', nameKatakana: 'ヤマグチケンシンヨウクミアイ' },
  { code: '2721', nameKanji: '香川県信用組合', nameKatakana: 'カガワケンシンヨウクミアイ' },
  { code: '2740', nameKanji: '土佐信用組合', nameKatakana: 'トサシンヨウクミアイ' },
  { code: '2741', nameKanji: '宿毛商銀信用組合', nameKatakana: 'スクモシヨウギンシンヨウクミアイ' },
  { code: '2751', nameKanji: '福岡県庁信用組合', nameKatakana: 'フクオカケンチヨウシンヨウクミアイ' },
  { code: '2753', nameKanji: '福岡県医師信用組合', nameKatakana: 'フクオカケンイシシンヨウクミアイ' },
  { code: '2773', nameKanji: '福岡県信用組合', nameKatakana: 'フクオカケンシンヨウクミアイ' },
  { code: '2802', nameKanji: '佐賀県医師信用組合', nameKatakana: 'サガケンイシシンヨウクミアイ' },
  { code: '2803', nameKanji: '佐賀東信用組合', nameKatakana: 'サガヒガシシンヨウクミアイ' },
  { code: '2808', nameKanji: '佐賀西信用組合', nameKatakana: 'サガニシシンヨウクミアイ' },
  { code: '2820', nameKanji: '長崎三菱信用組合', nameKatakana: 'ナガサキミツビシシンヨウクミアイ' },
  { code: '2821', nameKanji: '長崎県医師信用組合', nameKatakana: 'ナガサキケンイシシンヨウクミアイ' },
  { code: '2825', nameKanji: '西海みずき信用組合', nameKatakana: 'サイカイミズキシンヨウクミアイ' },
  { code: '2833', nameKanji: '福江信用組合', nameKatakana: 'フクエシンヨウクミアイ' },
  { code: '2842', nameKanji: '熊本県医師信用組合', nameKatakana: 'クマモトケンイシシンヨウクミアイ' },
  { code: '2845', nameKanji: '熊本県信用組合', nameKatakana: 'クマモトケンシンヨウクミアイ' },
  { code: '2870', nameKanji: '大分県信用組合', nameKatakana: 'オオイタケンシンヨウクミアイ' },
  { code: '2884', nameKanji: '宮崎県南部信用組合', nameKatakana: 'ミヤザキケンナンブシンヨウクミアイ' },
  { code: '2890', nameKanji: '鹿児島興業信用組合', nameKatakana: 'カゴシマコウギヨウシンヨウクミアイ' },
  { code: '2891', nameKanji: '鹿児島県医師信用組合', nameKatakana: 'カゴシマケンイシシンヨウクミアイ' },
  { code: '2895', nameKanji: '奄美信用組合', nameKatakana: 'アマミシンヨウクミアイ' },
  { code: '2950', nameKanji: '労金連', nameKatakana: 'ロウキンレン' },
  { code: '2951', nameKanji: '北海道労働金庫', nameKatakana: 'ホツカイドウロウドウキンコ' },
  { code: '2954', nameKanji: '東北労働金庫', nameKatakana: 'トウホクロウドウキンコ' },
  { code: '2963', nameKanji: '中央労働金庫', nameKatakana: 'チユウオウロウドウキンコ' },
  { code: '2965', nameKanji: '新潟県労働金庫', nameKatakana: 'ニイガタケンロウドウキンコ' },
  { code: '2966', nameKanji: '長野県労働金庫', nameKatakana: 'ナガノケンロウドウキンコ' },
  { code: '2968', nameKanji: '静岡県労働金庫', nameKatakana: 'シズオカケンロウドウキンコ' },
  { code: '2970', nameKanji: '北陸労働金庫', nameKatakana: 'ホクリクロウドウキンコ' },
  { code: '2972', nameKanji: '東海労働金庫', nameKatakana: 'トウカイロウドウキンコ' },
  { code: '2978', nameKanji: '近畿労働金庫', nameKatakana: 'キンキロウドウキンコ' },
  { code: '2984', nameKanji: '中国労働金庫', nameKatakana: 'チユウゴクロウドウキンコ' },
  { code: '2987', nameKanji: '四国労働金庫', nameKatakana: 'シコクロウドウキンコ' },
  { code: '2990', nameKanji: '九州労働金庫', nameKatakana: 'キユウシユウロウドウキンコ' },
  { code: '2997', nameKanji: '沖縄県労働金庫', nameKatakana: 'オキナワケンロウドウキンコ' },
  { code: '3000', nameKanji: '農林中金', nameKatakana: 'ノウリンチユウキン' },
  { code: '3001', nameKanji: '北海道信用農業協同組合連合会', nameKatakana: 'ホツカイドウシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3003', nameKanji: '岩手県信用農業協同組合連合会', nameKatakana: 'イワテケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3008', nameKanji: '茨城県信用農業協同組合連合会', nameKatakana: 'イバラキケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3011', nameKanji: '埼玉県信用農業協同組合連合会', nameKatakana: 'サイタマケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3013', nameKanji: '東京都信用農業協同組合連合会', nameKatakana: 'トウキヨウトシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3014', nameKanji: '神奈川県信用農業協同組合連合会', nameKatakana: 'カナガワケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3015', nameKanji: '山梨県信用農業協同組合連合会', nameKatakana: 'ヤマナシケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3016', nameKanji: '長野県信用農業協同組合連合会', nameKatakana: 'ナガノケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3017', nameKanji: '新潟県信用農業協同組合連合会', nameKatakana: 'ニイガタケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3019', nameKanji: '石川県信用農業協同組合連合会', nameKatakana: 'イシカワケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3020', nameKanji: '岐阜県信用農業協同組合連合会', nameKatakana: 'ギフケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3021', nameKanji: '静岡県信用農業協同組合連合会', nameKatakana: 'シズオカケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3022', nameKanji: '愛知県信用農業協同組合連合会', nameKatakana: 'アイチケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3023', nameKanji: '三重県信用農業協同組合連合会', nameKatakana: 'ミエケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3024', nameKanji: '福井県信用農業協同組合連合会', nameKatakana: 'フクイケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3025', nameKanji: '滋賀県信用農業協同組合連合会', nameKatakana: 'シガケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3026', nameKanji: '京都府信用農業協同組合連合会', nameKatakana: 'キヨウトフシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3027', nameKanji: '大阪府信用農業協同組合連合会', nameKatakana: 'オオサカフシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3028', nameKanji: '兵庫県信用農業協同組合連合会', nameKatakana: 'ヒヨウゴケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3030', nameKanji: '和歌山県信用農業協同組合連合会', nameKatakana: 'ワカヤマケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3031', nameKanji: '鳥取県信用農業協同組合連合会', nameKatakana: 'トツトリケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3034', nameKanji: '広島県信用農業協同組合連合会', nameKatakana: 'ヒロシマケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3035', nameKanji: '山口県信用農業協同組合連合会', nameKatakana: 'ヤマグチケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3036', nameKanji: '徳島県信用農業協同組合連合会', nameKatakana: 'トクシマケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3037', nameKanji: '香川県信用農業協同組合連合会', nameKatakana: 'カガワケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3038', nameKanji: '愛媛県信用農業協同組合連合会', nameKatakana: 'エヒメケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3039', nameKanji: '高知県信用農業協同組合連合会', nameKatakana: 'コウチケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3040', nameKanji: '福岡県信用農業協同組合連合会', nameKatakana: 'フクオカケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3041', nameKanji: '佐賀県信用農業協同組合連合会', nameKatakana: 'サガケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3044', nameKanji: '大分県信用農業協同組合連合会', nameKatakana: 'オオイタケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3045', nameKanji: '宮崎県信用農業協同組合連合会', nameKatakana: 'ミヤザキケンシンヨウノウギョウキョウドウクミアイレンゴウカイ' },
  { code: '3046', nameKanji: '鹿児島県信連', nameKatakana: 'カゴシマケンシンレン' },
  { code: '3058', nameKanji: '今金町農業協同組合', nameKatakana: 'イマガネチヨウノウギョウキヨウドウクミアイ' },
  { code: '3066', nameKanji: '函館市亀田農業協同組合', nameKatakana: 'ハコダテシカメダノウギョウキヨウドウクミアイ' },
  { code: '3068', nameKanji: '新函館農業協同組合', nameKatakana: 'シンハコダテノウギョウキヨウドウクミアイ' },
  { code: '3086', nameKanji: 'ようてい農業協同組合', nameKatakana: 'ヨウテイノウギョウキヨウドウクミアイ' },
  { code: '3087', nameKanji: 'きょうわ農業協同組合', nameKatakana: 'キヨウワノウギョウキヨウドウクミアイ' },
  { code: '3094', nameKanji: '新おたる農業協同組合', nameKatakana: 'シンオタルノウギョウキヨウドウクミアイ' },
  { code: '3095', nameKanji: '余市町農業協同組合', nameKatakana: 'ヨイチチヨウノウギョウキヨウドウクミアイ' },
  { code: '3103', nameKanji: 'とうや湖農業協同組合', nameKatakana: 'トウヤコノウギョウキヨウドウクミアイ' },
  { code: '3107', nameKanji: '伊達市農業協同組合', nameKatakana: 'ダテシノウギョウキヨウドウクミアイ' },
  { code: '3112', nameKanji: 'とまこまい広域農業協同組合', nameKatakana: 'トマコマイコウイキノウギョウキヨウドウクミアイ' },
  { code: '3114', nameKanji: '鵡川農業協同組合', nameKatakana: 'ムカワノウギョウキヨウドウクミアイ' },
  { code: '3120', nameKanji: 'びらとり農業協同組合', nameKatakana: 'ビラトリノウギョウキヨウドウクミアイ' },
  { code: '3122', nameKanji: '門別町農業協同組合', nameKatakana: 'モンベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3126', nameKanji: 'みついし農業協同組合', nameKatakana: 'ミツイシノウギョウキヨウドウクミアイ' },
  { code: '3133', nameKanji: '札幌市農業協同組合', nameKatakana: 'サツポロシノウギョウキヨウドウクミアイ' },
  { code: '3139', nameKanji: '道央農業協同組合', nameKatakana: 'ドウオウノウギョウキヨウドウクミアイ' },
  { code: '3145', nameKanji: '北石狩農業協同組合', nameKatakana: 'キタイシカリノウギョウキヨウドウクミアイ' },
  { code: '3147', nameKanji: '新篠津村農業協同組合', nameKatakana: 'シンシノツムラノウギョウキヨウドウクミアイ' },
  { code: '3154', nameKanji: 'サツラク農業協同組合', nameKatakana: 'サツラクノウギョウキヨウドウクミアイ' },
  { code: '3156', nameKanji: 'いわみざわ農業協同組合', nameKatakana: 'イワミザワノウギョウキヨウドウクミアイ' },
  { code: '3161', nameKanji: '南幌町農業協同組合', nameKatakana: 'ナンポロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3164', nameKanji: '美唄市農業協同組合', nameKatakana: 'ビバイシノウギョウキヨウドウクミアイ' },
  { code: '3165', nameKanji: '峰延農業協同組合', nameKatakana: 'ミネノブノウギョウキヨウドウクミアイ' },
  { code: '3168', nameKanji: '月形町農業協同組合', nameKatakana: 'ツキガタチヨウノウギョウキヨウドウクミアイ' },
  { code: '3170', nameKanji: 'ながぬま農業協同組合', nameKatakana: 'ナガヌマノウギョウキヨウドウクミアイ' },
  { code: '3172', nameKanji: 'そらち南農業協同組合', nameKatakana: 'ソラチミナミノウギョウキヨウドウクミアイ' },
  { code: '3173', nameKanji: '夕張市農業協同組合', nameKatakana: 'ユウバリシノウギョウキヨウドウクミアイ' },
  { code: '3175', nameKanji: '新砂川農業協同組合', nameKatakana: 'シンスナガワノウギョウキヨウドウクミアイ' },
  { code: '3177', nameKanji: 'たきかわ農業協同組合', nameKatakana: 'タキカワノウギョウキヨウドウクミアイ' },
  { code: '3181', nameKanji: 'ピンネ農業協同組合', nameKatakana: 'ピンネノウギョウキヨウドウクミアイ' },
  { code: '3188', nameKanji: '北いぶき農業協同組合', nameKatakana: 'キタイブキノウギョウキヨウドウクミアイ' },
  { code: '3189', nameKanji: 'きたそらち農業協同組合', nameKatakana: 'キタソラチノウギョウキヨウドウクミアイ' },
  { code: '3202', nameKanji: 'るもい農業協同組合', nameKatakana: 'ルモイノウギョウキヨウドウクミアイ' },
  { code: '3208', nameKanji: '幌延町農業協同組合', nameKatakana: 'ホロノベチヨウノウギョウキヨウドウクミアイ' },
  { code: '3210', nameKanji: 'あさひかわ農業協同組合', nameKatakana: 'アサヒカワノウギョウキヨウドウクミアイ' },
  { code: '3214', nameKanji: 'たいせつ農業協同組合', nameKatakana: 'タイセツノウギョウキヨウドウクミアイ' },
  { code: '3219', nameKanji: '東神楽農業協同組合', nameKatakana: 'ヒガシカグラノウギョウキヨウドウクミアイ' },
  { code: '3220', nameKanji: '東旭川農業協同組合', nameKatakana: 'ヒガシアサヒカワノウギョウキヨウドウクミアイ' },
  { code: '3223', nameKanji: '当麻農業協同組合', nameKatakana: 'トウマノウギョウキヨウドウクミアイ' },
  { code: '3224', nameKanji: '比布町農業協同組合', nameKatakana: 'ピツプチヨウノウギョウキヨウドウクミアイ' },
  { code: '3225', nameKanji: '上川中央農業協同組合', nameKatakana: 'カミカワチユウオウノウギョウキヨウドウクミアイ' },
  { code: '3227', nameKanji: '東川町農業協同組合', nameKatakana: 'ヒガシカワチヨウノウギョウキヨウドウクミアイ' },
  { code: '3228', nameKanji: '美瑛町農業協同組合', nameKatakana: 'ビエイチヨウノウギョウキヨウドウクミアイ' },
  { code: '3231', nameKanji: 'ふらの農業協同組合', nameKatakana: 'フラノノウギョウキヨウドウクミアイ' },
  { code: '3238', nameKanji: '北ひびき農業協同組合', nameKatakana: 'キタヒビキノウギョウキヨウドウクミアイ' },
  { code: '3244', nameKanji: '道北なよろ農業協同組合', nameKatakana: 'ドウホクナヨロノウギョウキヨウドウクミアイ' },
  { code: '3248', nameKanji: '北はるか農業協同組合', nameKatakana: 'キタハルカノウギョウキヨウドウクミアイ' },
  { code: '3257', nameKanji: '北宗谷農業協同組合', nameKatakana: 'キタソウヤノウギョウキヨウドウクミアイ' },
  { code: '3259', nameKanji: '東宗谷農業協同組合', nameKatakana: 'ヒガシソウヤノウギョウキヨウドウクミアイ' },
  { code: '3261', nameKanji: '宗谷南農業協同組合', nameKatakana: 'ソウヤミナミノウギョウキヨウドウクミアイ' },
  { code: '3264', nameKanji: '帯広市川西農業協同組合', nameKatakana: 'オビヒロシカワニシノウギョウキヨウドウクミアイ' },
  { code: '3265', nameKanji: '帯広大正農業協同組合', nameKatakana: 'オビヒロタイシヨウノウギョウキヨウドウクミアイ' },
  { code: '3266', nameKanji: '中札内村農業協同組合', nameKatakana: 'ナカサツナイムラノウギョウキヨウドウクミアイ' },
  { code: '3267', nameKanji: '更別村農業協同組合', nameKatakana: 'サラベツムラノウギョウキヨウドウクミアイ' },
  { code: '3268', nameKanji: '忠類農業協同組合', nameKatakana: 'チユウルイノウギョウキヨウドウクミアイ' },
  { code: '3269', nameKanji: '大樹町農業協同組合', nameKatakana: 'タイキチヨウノウギョウキヨウドウクミアイ' },
  { code: '3270', nameKanji: '広尾町農業協同組合', nameKatakana: 'ヒロオチヨウノウギョウキヨウドウクミアイ' },
  { code: '3271', nameKanji: '芽室町農業協同組合', nameKatakana: 'メムロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3273', nameKanji: '十勝清水町農業協同組合', nameKatakana: 'トカチシミズチヨウノウギョウキヨウドウクミアイ' },
  { code: '3275', nameKanji: '新得町農業協同組合', nameKatakana: 'シントクチヨウノウギョウキヨウドウクミアイ' },
  { code: '3276', nameKanji: '鹿追町農業協同組合', nameKatakana: 'シカオイチヨウノウギョウキヨウドウクミアイ' },
  { code: '3277', nameKanji: '木野農業協同組合', nameKatakana: 'キノノウギョウキヨウドウクミアイ' },
  { code: '3278', nameKanji: '音更町農業協同組合', nameKatakana: 'オトフケチヨウノウギョウキヨウドウクミアイ' },
  { code: '3279', nameKanji: '士幌町農業協同組合', nameKatakana: 'シホロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3280', nameKanji: '上士幌町農業協同組合', nameKatakana: 'カミシホロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3281', nameKanji: '札内農業協同組合', nameKatakana: 'サツナイノウギョウキヨウドウクミアイ' },
  { code: '3282', nameKanji: '幕別町農業協同組合', nameKatakana: 'マクベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3283', nameKanji: '十勝池田町農業協同組合', nameKatakana: 'トカチイケダチヨウノウギョウキヨウドウクミアイ' },
  { code: '3286', nameKanji: '豊頃町農業協同組合', nameKatakana: 'トヨコロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3287', nameKanji: '浦幌町農業協同組合', nameKatakana: 'ウラホロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3288', nameKanji: '本別町農業協同組合', nameKatakana: 'ホンベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3289', nameKanji: '足寄町農業協同組合', nameKatakana: 'アシヨロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3290', nameKanji: '陸別町農業協同組合', nameKatakana: 'リクベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3297', nameKanji: '北オホーツク農業協同組合', nameKatakana: 'キタオホ－ツクノウギョウキヨウドウクミアイ' },
  { code: '3301', nameKanji: 'オホーツクはまなす農業協同組合', nameKatakana: 'オホ－ツクハマナスノウギョウキヨウドウクミアイ' },
  { code: '3303', nameKanji: '佐呂間町農業協同組合', nameKatakana: 'サロマチヨウノウギョウキヨウドウクミアイ' },
  { code: '3305', nameKanji: '湧別町農業協同組合', nameKatakana: 'ユウベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3306', nameKanji: 'えんゆう農業協同組合', nameKatakana: 'エンユウノウギョウキヨウドウクミアイ' },
  { code: '3317', nameKanji: 'きたみらい農業協同組合', nameKatakana: 'キタミライノウギョウキヨウドウクミアイ' },
  { code: '3319', nameKanji: '津別町農業協同組合', nameKatakana: 'ツベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3320', nameKanji: '美幌町農業協同組合', nameKatakana: 'ビホロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3321', nameKanji: '女満別町農業協同組合', nameKatakana: 'メマンベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3322', nameKanji: '常呂町農業協同組合', nameKatakana: 'トコロチヨウノウギョウキヨウドウクミアイ' },
  { code: '3326', nameKanji: 'オホーツク網走農業協同組合', nameKatakana: 'オホ－ツクアバシリノウギョウキヨウドウクミアイ' },
  { code: '3328', nameKanji: '小清水町農業協同組合', nameKatakana: 'コシミズチヨウノウギョウキヨウドウクミアイ' },
  { code: '3329', nameKanji: 'しれとこ斜里農業協同組合', nameKatakana: 'シレトコシヤリノウギョウキヨウドウクミアイ' },
  { code: '3330', nameKanji: '清里町農業協同組合', nameKatakana: 'キヨサトチヨウノウギョウキヨウドウクミアイ' },
  { code: '3334', nameKanji: '釧路太田農業協同組合', nameKatakana: 'クシロオオタノウギョウキヨウドウクミアイ' },
  { code: '3335', nameKanji: '浜中町農業協同組合', nameKatakana: 'ハマナカチヨウノウギョウキヨウドウクミアイ' },
  { code: '3336', nameKanji: '標茶町農業協同組合', nameKatakana: 'シベチヤチヨウノウギョウキヨウドウクミアイ' },
  { code: '3337', nameKanji: '摩周湖農業協同組合', nameKatakana: 'マシユウコノウギョウキヨウドウクミアイ' },
  { code: '3338', nameKanji: '阿寒農業協同組合', nameKatakana: 'アカンノウギョウキヨウドウクミアイ' },
  { code: '3339', nameKanji: '釧路丹頂農業協同組合', nameKatakana: 'クシロタンチヨウノウギョウキヨウドウクミアイ' },
  { code: '3348', nameKanji: '標津町農業協同組合', nameKatakana: 'シベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3349', nameKanji: '中標津町農業協同組合', nameKatakana: 'ナカシベツチヨウノウギョウキヨウドウクミアイ' },
  { code: '3350', nameKanji: '計根別農業協同組合', nameKatakana: 'ケネベツノウギョウキヨウドウクミアイ' },
  { code: '3354', nameKanji: '道東あさひ農業協同組合', nameKatakana: 'ドウトウアサヒノウギョウキヨウドウクミアイ' },
  { code: '3358', nameKanji: '中春別農業協同組合', nameKatakana: 'ナカシユンベツノウギョウキヨウドウクミアイ' },
  { code: '3373', nameKanji: '青森農業協同組合', nameKatakana: 'アオモリノウギョウキヨウドウクミアイ' },
  { code: '3387', nameKanji: 'つがる弘前農業協同組合', nameKatakana: 'ツガルヒロサキノウギョウキヨウドウクミアイ' },
  { code: '3390', nameKanji: '相馬村農業協同組合', nameKatakana: 'ソウマムラノウギョウキヨウドウクミアイ' },
  { code: '3407', nameKanji: '津軽みらい農業協同組合', nameKatakana: 'ツガルミライノウギョウキヨウドウクミアイ' },
  { code: '3421', nameKanji: 'つがるにしきた農業協同組合', nameKatakana: 'ツガルニシキタノウギョウキヨウドウクミアイ' },
  { code: '3442', nameKanji: 'ごしょつがる農業協同組合', nameKatakana: 'ゴシヨツガルノウギョウキヨウドウクミアイ' },
  { code: '3455', nameKanji: '十和田おいらせ農業協同組合', nameKatakana: 'トワダオイラセノウギョウキヨウドウクミアイ' },
  { code: '3469', nameKanji: 'ゆうき青森農業協同組合', nameKatakana: 'ユウキアオモリノウギョウキヨウドウクミアイ' },
  { code: '3474', nameKanji: 'おいらせ農業協同組合', nameKatakana: 'オイラセノウギョウキヨウドウクミアイ' },
  { code: '3488', nameKanji: '八戸農業協同組合', nameKatakana: 'ハチノヘノウギョウキヨウドウクミアイ' },
  { code: '3517', nameKanji: '新岩手農業協同組合', nameKatakana: 'シンイワテノウギョウキヨウドウクミアイ' },
  { code: '3541', nameKanji: '岩手中央農業協同組合', nameKatakana: 'イワテチユウオウノウギョウキヨウドウクミアイ' },
  { code: '3553', nameKanji: '花巻農業協同組合', nameKatakana: 'ハナマキノウギョウキヨウドウクミアイ' },
  { code: '3572', nameKanji: '岩手ふるさと農業協同組合', nameKatakana: 'イワテフルサトノウギョウキヨウドウクミアイ' },
  { code: '3579', nameKanji: '岩手江刺農業協同組合', nameKatakana: 'イワテエサシノウギョウキヨウドウクミアイ' },
  { code: '3590', nameKanji: 'いわて平泉農業協同組合', nameKatakana: 'イワテヒライズミノウギョウキヨウドウクミアイ' },
  { code: '3598', nameKanji: '大船渡市農業協同組合', nameKatakana: 'オオフナトシノウギョウキヨウドウクミアイ' },
  { code: '3636', nameKanji: '仙台農業協同組合', nameKatakana: 'センダイノウギョウキヨウドウクミアイ' },
  { code: '3647', nameKanji: '岩沼市農業協同組合', nameKatakana: 'イワヌマシノウギョウキヨウドウクミアイ' },
  { code: '3652', nameKanji: '名取岩沼農業協同組合', nameKatakana: 'ナトリイワヌマノウギョウキヨウドウクミアイ' },
  { code: '3653', nameKanji: 'みやぎ亘理農業協同組合', nameKatakana: 'ミヤギワタリノウギョウキヨウドウクミアイ' },
  { code: '3665', nameKanji: 'みやぎ登米農業協同組合', nameKatakana: 'ミヤギトメノウギョウキヨウドウクミアイ' },
  { code: '3704', nameKanji: '古川農業協同組合', nameKatakana: 'フルカワノウギョウキヨウドウクミアイ' },
  { code: '3710', nameKanji: '加美よつば農業協同組合', nameKatakana: 'カミヨツバノウギョウキヨウドウクミアイ' },
  { code: '3721', nameKanji: '新みやぎ農業協同組合', nameKatakana: 'シンミヤギノウギョウキヨウドウクミアイ' },
  { code: '3731', nameKanji: 'いしのまき農業協同組合', nameKatakana: 'イシノマキノウギョウキヨウドウクミアイ' },
  { code: '3751', nameKanji: 'みやぎ仙南農業協同組合', nameKatakana: 'ミヤギセンナンノウギョウキヨウドウクミアイ' },
  { code: '3762', nameKanji: 'かづの農業協同組合', nameKatakana: 'カヅノノウギョウキヨウドウクミアイ' },
  { code: '3764', nameKanji: 'あきた北農業協同組合', nameKatakana: 'アキタキタノウギョウキヨウドウクミアイ' },
  { code: '3771', nameKanji: '秋田たかのす', nameKatakana: 'アキタタカノスノウギョウキヨウドウクミアイ' },
  { code: '3784', nameKanji: 'あきた白神農業協同組合', nameKatakana: 'アキタシラカミノウギョウキヨウドウクミアイ' },
  { code: '3795', nameKanji: '秋田やまもと農業協同組合', nameKatakana: 'アキタヤマモトノウギョウキヨウドウクミアイ' },
  { code: '3798', nameKanji: 'あきた湖東農業協同組合', nameKatakana: 'アキタコトウノウギョウキヨウドウクミアイ' },
  { code: '3810', nameKanji: '秋田なまはげ農業協同組合', nameKatakana: 'アキタナマハゲノウギョウキヨウドウクミアイ' },
  { code: '3825', nameKanji: '秋田しんせい農業協同組合', nameKatakana: 'アキタシンセイノウギョウキヨウドウクミアイ' },
  { code: '3855', nameKanji: '秋田おばこ農業協同組合', nameKatakana: 'アキタオバコノウギョウキヨウドウクミアイ' },
  { code: '3878', nameKanji: '秋田ふるさと農業協同組合', nameKatakana: 'アキタフルサトノウギョウキヨウドウクミアイ' },
  { code: '3913', nameKanji: 'こまち農業協同組合', nameKatakana: 'コマチノウギョウキヨウドウクミアイ' },
  { code: '3917', nameKanji: 'うご農業協同組合', nameKatakana: 'ウゴノウギョウキヨウドウクミアイ' },
  { code: '3929', nameKanji: '大潟村農業協同組合', nameKatakana: 'オオガタムラノウギョウキヨウドウクミアイ' },
  { code: '3931', nameKanji: '山形市農業協同組合', nameKatakana: 'ヤマガタシノウギョウキヨウドウクミアイ' },
  { code: '3932', nameKanji: '山形農業協同組合', nameKatakana: 'ヤマガタノウギョウキヨウドウクミアイ' },
  { code: '3938', nameKanji: '天童市農業協同組合', nameKatakana: 'テンドウシノウギョウキヨウドウクミアイ' },
  { code: '3943', nameKanji: 'さがえ西村山農業協同組合', nameKatakana: 'サガエニシムラヤマノウギョウキヨウドウクミアイ' },
  { code: '3960', nameKanji: 'みちのく村山農業協同組合', nameKatakana: 'ミチノクムラヤマノウギョウキヨウドウクミアイ' },
  { code: '3962', nameKanji: '東根市農業協同組合', nameKatakana: 'ヒガシネシノウギョウキヨウドウクミアイ' },
  { code: '3971', nameKanji: '新庄市農業協同組合', nameKatakana: 'シンジヨウシノウギョウキヨウドウクミアイ' },
  { code: '3973', nameKanji: 'もがみ中央農業協同組合', nameKatakana: 'モガミチユウオウノウギョウキヨウドウクミアイ' },
  { code: '3987', nameKanji: '金山農業協同組合', nameKatakana: 'カネヤマノウギョウキヨウドウクミアイ' },
  { code: '3989', nameKanji: '山形おきたま農業協同組合', nameKatakana: 'ヤマガタオキタマノウギョウキヨウドウクミアイ' },
  { code: '4000', nameKanji: '鶴岡市農業協同組合', nameKatakana: 'ツルオカシノウギョウキヨウドウクミアイ' },
  { code: '4013', nameKanji: '庄内たがわ農業協同組合', nameKatakana: 'シヨウナイタガワノウギョウキヨウドウクミアイ' },
  { code: '4022', nameKanji: '余目町農業協同組合', nameKatakana: 'アマルメマチノウギョウキヨウドウクミアイ' },
  { code: '4027', nameKanji: '庄内みどり農業協同組合', nameKatakana: 'シヨウナイミドリノウギョウキヨウドウクミアイ' },
  { code: '4036', nameKanji: '酒田市袖浦農業協同組合', nameKatakana: 'サカタシソデウラノウギョウキヨウドウクミアイ' },
  { code: '4047', nameKanji: 'ふくしま未来農業協同組合', nameKatakana: 'フクシマミライノウギョウキヨウドウクミアイ' },
  { code: '4091', nameKanji: '夢みなみ農業協同組合', nameKatakana: 'ユメミナミノウギョウキヨウドウクミアイ' },
  { code: '4132', nameKanji: '東西しらかわ農業協同組合', nameKatakana: 'トウザイシラカワノウギョウキヨウドウクミアイ' },
  { code: '4160', nameKanji: '会津よつば農業協同組合', nameKatakana: 'アイヅヨツバノウギョウキヨウドウクミアイ' },
  { code: '4196', nameKanji: '福島さくら農業協同組合', nameKatakana: 'フクシマサクラノウギョウキヨウドウクミアイ' },
  { code: '4238', nameKanji: '水戸農業協同組合', nameKatakana: 'ミトノウギョウキヨウドウクミアイ' },
  { code: '4263', nameKanji: '常陸農業協同組合', nameKatakana: 'ヒタチノウギョウキヨウドウクミアイ' },
  { code: '4294', nameKanji: '日立市多賀農業協同組合', nameKatakana: 'ヒタチシタガノウギョウキヨウドウクミアイ' },
  { code: '4295', nameKanji: '茨城旭村農業協同組合', nameKatakana: 'イバラキアサヒムラノウギョウキヨウドウクミアイ' },
  { code: '4296', nameKanji: 'ほこた農業協同組合', nameKatakana: 'ホコタノウギョウキヨウドウクミアイ' },
  { code: '4301', nameKanji: 'なめがたしおさい農業協同組合', nameKatakana: 'ナメガタシオサイノウギョウキヨウドウクミアイ' },
  { code: '4322', nameKanji: '稲敷農業協同組合', nameKatakana: 'イナシキノウギョウキヨウドウクミアイ' },
  { code: '4344', nameKanji: '水郷つくば農業協同組合', nameKatakana: 'スイゴウツクバノウギョウキヨウドウクミアイ' },
  { code: '4363', nameKanji: 'つくば市農業協同組合', nameKatakana: 'ツクバシノウギョウキヨウドウクミアイ' },
  { code: '4371', nameKanji: 'つくば市谷田部農業協同組合', nameKatakana: 'ツクバシヤタベノウギョウキヨウドウクミアイ' },
  { code: '4378', nameKanji: '茨城みなみ農業協同組合', nameKatakana: 'イバラキミナミノウギョウキヨウドウクミアイ' },
  { code: '4387', nameKanji: 'やさと農業協同組合', nameKatakana: 'ヤサトノウギョウキヨウドウクミアイ' },
  { code: '4394', nameKanji: '新ひたち野農業協同組合', nameKatakana: 'シンヒタチノノウギョウキヨウドウクミアイ' },
  { code: '4397', nameKanji: '北つくば農業協同組合', nameKatakana: 'キタツクバノウギョウキヨウドウクミアイ' },
  { code: '4413', nameKanji: '常総ひかり農業協同組合', nameKatakana: 'ジヨウソウヒカリノウギョウキヨウドウクミアイ' },
  { code: '4422', nameKanji: '茨城むつみ農業協同組合', nameKatakana: 'イバラキムツミノウギョウキヨウドウクミアイ' },
  { code: '4425', nameKanji: '岩井農業協同組合', nameKatakana: 'イワイノウギョウキヨウドウクミアイ' },
  { code: '4445', nameKanji: '宇都宮農業協同組合', nameKatakana: 'ウツノミヤノウギョウキヨウドウクミアイ' },
  { code: '4456', nameKanji: '上都賀農業協同組合', nameKatakana: 'カミツガノウギョウキヨウドウクミアイ' },
  { code: '4463', nameKanji: 'はが野農業協同組合', nameKatakana: 'ハガノノウギョウキヨウドウクミアイ' },
  { code: '4478', nameKanji: '下野農業協同組合', nameKatakana: 'シモツケノウギョウキヨウドウクミアイ' },
  { code: '4490', nameKanji: '小山農業協同組合', nameKatakana: 'オヤマノウギョウキヨウドウクミアイ' },
  { code: '4497', nameKanji: '塩野谷農業協同組合', nameKatakana: 'シオノヤノウギョウキヨウドウクミアイ' },
  { code: '4507', nameKanji: '那須野農業協同組合', nameKatakana: 'ナスノノウギョウキヨウドウクミアイ' },
  { code: '4518', nameKanji: '那須南農業協同組合', nameKatakana: 'ナスミナミノウギョウキヨウドウクミアイ' },
  { code: '4523', nameKanji: '佐野農業協同組合', nameKatakana: 'サノノウギョウキヨウドウクミアイ' },
  { code: '4533', nameKanji: '足利市農業協同組合', nameKatakana: 'アシカガシノウギョウキヨウドウクミアイ' },
  { code: '4540', nameKanji: '赤城橘農業協同組合', nameKatakana: 'アカギタチバナノウギョウキヨウドウクミアイ' },
  { code: '4544', nameKanji: '前橋市農業協同組合', nameKatakana: 'マエバシシノウギョウキヨウドウクミアイ' },
  { code: '4563', nameKanji: '高崎市農業協同組合', nameKatakana: 'タカサキシノウギョウキヨウドウクミアイ' },
  { code: '4567', nameKanji: 'はぐくみ農業協同組合', nameKatakana: 'ハグクミノウギョウキヨウドウクミアイ' },
  { code: '4593', nameKanji: '北群渋川農業協同組合', nameKatakana: 'キタグンシブカワノウギョウキヨウドウクミアイ' },
  { code: '4594', nameKanji: '多野藤岡農業協同組合', nameKatakana: 'タノフジオカノウギョウキヨウドウクミアイ' },
  { code: '4608', nameKanji: '甘楽富岡農業協同組合', nameKatakana: 'カンラトミオカノウギョウキヨウドウクミアイ' },
  { code: '4613', nameKanji: '碓氷安中農業協同組合', nameKatakana: 'ウスイアンナカノウギョウキヨウドウクミアイ' },
  { code: '4626', nameKanji: 'あがつま農業協同組合', nameKatakana: 'アガツマノウギョウキヨウドウクミアイ' },
  { code: '4628', nameKanji: '嬬恋村農業協同組合', nameKatakana: 'ツマゴイムラノウギョウキヨウドウクミアイ' },
  { code: '4632', nameKanji: '利根沼田農業協同組合', nameKatakana: 'トネヌマタノウギョウキヨウドウクミアイ' },
  { code: '4652', nameKanji: '佐波伊勢崎農業協同組合', nameKatakana: 'サワイセサキノウギョウキヨウドウクミアイ' },
  { code: '4664', nameKanji: '新田みどり農業協同組合', nameKatakana: 'ニツタミドリノウギョウキヨウドウクミアイ' },
  { code: '4665', nameKanji: '太田市農業協同組合', nameKatakana: 'オオタシノウギョウキヨウドウクミアイ' },
  { code: '4677', nameKanji: '邑楽館林農業協同組合', nameKatakana: 'オウラタテバヤシノウギョウキヨウドウクミアイ' },
  { code: '4682', nameKanji: 'さいたま農業協同組合', nameKatakana: 'サイタマノウギョウキヨウドウクミアイ' },
  { code: '4730', nameKanji: 'あさか野農業協同組合', nameKatakana: 'アサカノノウギョウキヨウドウクミアイ' },
  { code: '4735', nameKanji: 'いるま野農業協同組合', nameKatakana: 'イルマノノウギョウキヨウドウクミアイ' },
  { code: '4780', nameKanji: '埼玉中央農業協同組合', nameKatakana: 'サイタマチユウオウノウギョウキヨウドウクミアイ' },
  { code: '4792', nameKanji: 'ちちぶ農業協同組合', nameKatakana: 'チチブノウギョウキヨウドウクミアイ' },
  { code: '4802', nameKanji: '埼玉ひびきの農業協同組合', nameKatakana: 'サイタマヒビキノノウギョウキヨウドウクミアイ' },
  { code: '4808', nameKanji: 'くまがや農業協同組合', nameKatakana: 'クマガヤノウギョウキヨウドウクミアイ' },
  { code: '4820', nameKanji: '埼玉岡部農業協同組合', nameKatakana: 'サイタマオカベノウギョウキヨウドウクミアイ' },
  { code: '4823', nameKanji: '花園農業協同組合', nameKatakana: 'ハナゾノノウギョウキヨウドウクミアイ' },
  { code: '4828', nameKanji: 'ほくさい農業協同組合', nameKatakana: 'ホクサイノウギョウキヨウドウクミアイ' },
  { code: '4847', nameKanji: '越谷市農業協同組合', nameKatakana: 'コシガヤシノウギョウキヨウドウクミアイ' },
  { code: '4848', nameKanji: '南彩農業協同組合', nameKatakana: 'ナンサイノウギョウキヨウドウクミアイ' },
  { code: '4859', nameKanji: '埼玉みずほ農業協同組合', nameKatakana: 'サイタマミズホノウギョウキヨウドウクミアイ' },
  { code: '4864', nameKanji: 'さいかつ農業協同組合', nameKatakana: 'サイカツノウギョウキヨウドウクミアイ' },
  { code: '4874', nameKanji: 'ふかや農業協同組合', nameKatakana: 'フカヤノウギョウキヨウドウクミアイ' },
  { code: '4876', nameKanji: '安房農業協同組合', nameKatakana: 'アワノウギョウキヨウドウクミアイ' },
  { code: '4893', nameKanji: 'いすみ農業協同組合', nameKatakana: 'イスミノウギョウキヨウドウクミアイ' },
  { code: '4902', nameKanji: '木更津市農業協同組合', nameKatakana: 'キサラヅシノウギョウキヨウドウクミアイ' },
  { code: '4909', nameKanji: '君津市農業協同組合', nameKatakana: 'キミツシノウギョウキヨウドウクミアイ' },
  { code: '4916', nameKanji: '長生農業協同組合', nameKatakana: 'チヨウセイノウギョウキヨウドウクミアイ' },
  { code: '4929', nameKanji: '山武郡市農業協同組合', nameKatakana: 'サンブグンシノウギョウキヨウドウクミアイ' },
  { code: '4949', nameKanji: '市原市農業協同組合', nameKatakana: 'イチハラシノウギョウキヨウドウクミアイ' },
  { code: '4954', nameKanji: '千葉みらい農業協同組合', nameKatakana: 'チバミライノウギョウキヨウドウクミアイ' },
  { code: '4955', nameKanji: '八千代市農業協同組合', nameKatakana: 'ヤチヨシノウギョウキヨウドウクミアイ' },
  { code: '4959', nameKanji: '市川市農業協同組合', nameKatakana: 'イチカワシノウギョウキヨウドウクミアイ' },
  { code: '4965', nameKanji: 'とうかつ中央農業協同組合', nameKatakana: 'トウカツチユウオウノウギョウキヨウドウクミアイ' },
  { code: '4975', nameKanji: 'ちば東葛農業協同組合', nameKatakana: 'チバトウカツノウギョウキヨウドウクミアイ' },
  { code: '4992', nameKanji: '成田市農業協同組合', nameKatakana: 'ナリタシノウギョウキヨウドウクミアイ' },
  { code: '4993', nameKanji: '富里市農業協同組合', nameKatakana: 'トミサトシノウギョウキヨウドウクミアイ' },
  { code: '4996', nameKanji: '西印旛農業協同組合', nameKatakana: 'ニシインバノウギョウキヨウドウクミアイ' },
  { code: '5000', nameKanji: 'かとり農業協同組合', nameKatakana: 'カトリノウギョウキヨウドウクミアイ' },
  { code: '5016', nameKanji: 'ちばみどり農業協同組合', nameKatakana: 'チバミドリノウギョウキヨウドウクミアイ' },
  { code: '5030', nameKanji: '西東京農業協同組合', nameKatakana: 'ニシトウキヨウノウギョウキヨウドウクミアイ' },
  { code: '5037', nameKanji: '西多摩農業協同組合', nameKatakana: 'ニシタマノウギョウキヨウドウクミアイ' },
  { code: '5039', nameKanji: '秋川農業協同組合', nameKatakana: 'アキガワノウギョウキヨウドウクミアイ' },
  { code: '5050', nameKanji: '八王子市農業協同組合', nameKatakana: 'ハチオウジシノウギョウキヨウドウクミアイ' },
  { code: '5055', nameKanji: '東京南農業協同組合', nameKatakana: 'トウキヨウミナミノウギョウキヨウドウクミアイ' },
  { code: '5060', nameKanji: '町田市農業協同組合', nameKatakana: 'マチダシノウギョウキヨウドウクミアイ' },
  { code: '5070', nameKanji: 'マインズ農業協同組合', nameKatakana: 'マインズノウギョウキヨウドウクミアイ' },
  { code: '5072', nameKanji: '東京みどり農業協同組合', nameKatakana: 'トウキヨウミドリノウギョウキヨウドウクミアイ' },
  { code: '5077', nameKanji: '東京みらい農業協同組合', nameKatakana: 'トウキヨウミライノウギョウキヨウドウクミアイ' },
  { code: '5087', nameKanji: '東京むさし農業協同組合', nameKatakana: 'トウキヨウムサシノウギョウキヨウドウクミアイ' },
  { code: '5094', nameKanji: '東京中央農業協同組合', nameKatakana: 'トウキヨウチユウオウノウギョウキヨウドウクミアイ' },
  { code: '5095', nameKanji: '世田谷目黒農業協同組合', nameKatakana: 'セタガヤメグロノウギョウキヨウドウクミアイ' },
  { code: '5097', nameKanji: '東京あおば農業協同組合', nameKatakana: 'トウキヨウアオバノウギョウキヨウドウクミアイ' },
  { code: '5100', nameKanji: '東京スマイル農業協同組合', nameKatakana: 'トウキヨウスマイルノウギョウキヨウドウクミアイ' },
  { code: '5114', nameKanji: '横浜農業協同組合', nameKatakana: 'ヨコハマノウギョウキヨウドウクミアイ' },
  { code: '5123', nameKanji: 'セレサ川崎農業協同組合', nameKatakana: 'セレサカワサキノウギョウキヨウドウクミアイ' },
  { code: '5128', nameKanji: 'よこすか葉山農業協同組合', nameKatakana: 'ヨコスカハヤマノウギョウキヨウドウクミアイ' },
  { code: '5130', nameKanji: '三浦市農業協同組合', nameKatakana: 'ミウラシノウギョウキヨウドウクミアイ' },
  { code: '5131', nameKanji: 'さがみ農業協同組合', nameKatakana: 'サガミノウギョウキヨウドウクミアイ' },
  { code: '5137', nameKanji: '湘南農業協同組合', nameKatakana: 'シヨウナンノウギョウキヨウドウクミアイ' },
  { code: '5140', nameKanji: '秦野市農業協同組合', nameKatakana: 'ハダノシノウギョウキヨウドウクミアイ' },
  { code: '5147', nameKanji: 'かながわ西湘農業協同組合', nameKatakana: 'カナガワセイシヨウノウギョウキヨウドウクミアイ' },
  { code: '5152', nameKanji: '厚木市農業協同組合', nameKatakana: 'アツギシノウギョウキヨウドウクミアイ' },
  { code: '5153', nameKanji: '県央愛川農業協同組合', nameKatakana: 'ケンオウアイカワノウギョウキヨウドウクミアイ' },
  { code: '5159', nameKanji: '相模原市農業協同組合', nameKatakana: 'サガミハラシノウギョウキヨウドウクミアイ' },
  { code: '5162', nameKanji: '神奈川つくい農業協同組合', nameKatakana: 'カナガワツクイノウギョウキヨウドウクミアイ' },
  { code: '5169', nameKanji: 'フルーツ山梨農業協同組合', nameKatakana: 'フル－ツヤマナシノウギョウキヨウドウクミアイ' },
  { code: '5199', nameKanji: '笛吹農業協同組合', nameKatakana: 'フエフキノウギョウキヨウドウクミアイ' },
  { code: '5207', nameKanji: '山梨みらい農業協同組合', nameKatakana: 'ヤマナシミライノウギョウキヨウドウクミアイ' },
  { code: '5243', nameKanji: '南アルプス市農業協同組合', nameKatakana: 'ミナミアルプスシノウギョウキヨウドウクミアイ' },
  { code: '5260', nameKanji: '梨北農業協同組合', nameKatakana: 'リホクノウギョウキヨウドウクミアイ' },
  { code: '5272', nameKanji: 'クレイン農業協同組合', nameKatakana: 'クレインノウギョウキヨウドウクミアイ' },
  { code: '5284', nameKanji: '北富士農業協同組合', nameKatakana: 'キタフジノウギョウキヨウドウクミアイ' },
  { code: '5287', nameKanji: '鳴沢村農業協同組合', nameKatakana: 'ナルサワムラノウギョウキヨウドウクミアイ' },
  { code: '5311', nameKanji: '長野八ヶ岳農業協同組合', nameKatakana: 'ナガノヤツガタケノウギョウキヨウドウクミアイ' },
  { code: '5330', nameKanji: '川上物産農業協同組合', nameKatakana: 'カワカミブツサンノウギョウキヨウドウクミアイ' },
  { code: '5335', nameKanji: '佐久浅間農業協同組合', nameKatakana: 'サクアサマノウギョウキヨウドウクミアイ' },
  { code: '5348', nameKanji: '信州うえだ農業協同組合', nameKatakana: 'シンシユウウエダノウギョウキヨウドウクミアイ' },
  { code: '5372', nameKanji: '信州諏訪農業協同組合', nameKatakana: 'シンシユウスワノウギョウキヨウドウクミアイ' },
  { code: '5384', nameKanji: '上伊那農業協同組合', nameKatakana: 'カミイナノウギョウキヨウドウクミアイ' },
  { code: '5405', nameKanji: 'みなみ信州農業協同組合', nameKatakana: 'ミナミシンシユウノウギョウキヨウドウクミアイ' },
  { code: '5437', nameKanji: '下伊那園芸農業協同組合', nameKatakana: 'シモイナエンゲイノウギョウキヨウドウクミアイ' },
  { code: '5441', nameKanji: '木曽農業協同組合', nameKatakana: 'キソノウギョウキヨウドウクミアイ' },
  { code: '5448', nameKanji: '松本ハイランド農業協同組合', nameKatakana: 'マツモトハイランドノウギョウキヨウドウクミアイ' },
  { code: '5462', nameKanji: '洗馬農業協同組合', nameKatakana: 'セバノウギョウキヨウドウクミアイ' },
  { code: '5466', nameKanji: 'あづみ農業協同組合', nameKatakana: 'アヅミノウギョウキヨウドウクミアイ' },
  { code: '5470', nameKanji: '大北農業協同組合', nameKatakana: 'ダイホクノウギョウキヨウドウクミアイ' },
  { code: '5477', nameKanji: 'グリーン長野農業協同組合', nameKatakana: 'グリ－ンナガノノウギョウキヨウドウクミアイ' },
  { code: '5491', nameKanji: '中野市農業協同組合', nameKatakana: 'ナカノシノウギョウキヨウドウクミアイ' },
  { code: '5499', nameKanji: 'ながの農業協同組合', nameKatakana: 'ナガノノウギョウキヨウドウクミアイ' },
  { code: '5554', nameKanji: '北新潟農業協同組合', nameKatakana: 'キタニイガタノウギョウキヨウドウクミアイ' },
  { code: '5600', nameKanji: '新潟かがやき農業協同組合', nameKatakana: 'ニイガタカガヤキノウギョウキヨウドウクミアイ' },
  { code: '5666', nameKanji: 'えちご中越農業協同組合', nameKatakana: 'エチゴチユウエツノウギョウキヨウドウクミアイ' },
  { code: '5707', nameKanji: 'みなみ魚沼農業協同組合', nameKatakana: 'ミナミウオヌマノウギョウキヨウドウクミアイ' },
  { code: '5714', nameKanji: '魚沼農業協同組合', nameKatakana: 'ウオヌマノウギョウキヨウドウクミアイ' },
  { code: '5768', nameKanji: 'えちご上越農業協同組合', nameKatakana: 'エチゴジヨウエツノウギョウキヨウドウクミアイ' },
  { code: '5832', nameKanji: '佐渡農業協同組合', nameKatakana: 'サドノウギョウキヨウドウクミアイ' },
  { code: '5864', nameKanji: '新潟市農業協同組合', nameKatakana: 'ニイガタシノウギョウキヨウドウクミアイ' },
  { code: '5877', nameKanji: 'みな穂農業協同組合', nameKatakana: 'ミナホノウギョウキヨウドウクミアイ' },
  { code: '5883', nameKanji: '黒部市農業協同組合', nameKatakana: 'クロベシノウギョウキヨウドウクミアイ' },
  { code: '5885', nameKanji: '魚津市農業協同組合', nameKatakana: 'ウオヅシノウギョウキヨウドウクミアイ' },
  { code: '5888', nameKanji: 'アルプス農業協同組合', nameKatakana: 'アルプスノウギョウキヨウドウクミアイ' },
  { code: '5895', nameKanji: 'あおば農業協同組合', nameKatakana: 'アオバノウギョウキヨウドウクミアイ' },
  { code: '5897', nameKanji: '富山市農業協同組合', nameKatakana: 'トヤマシノウギョウキヨウドウクミアイ' },
  { code: '5898', nameKanji: 'なのはな農業協同組合', nameKatakana: 'ナノハナノウギョウキヨウドウクミアイ' },
  { code: '5911', nameKanji: 'いみず野農業協同組合', nameKatakana: 'イミズノノウギョウキヨウドウクミアイ' },
  { code: '5916', nameKanji: '高岡市農業協同組合', nameKatakana: 'タカオカシノウギョウキヨウドウクミアイ' },
  { code: '5920', nameKanji: '氷見市農業協同組合', nameKatakana: 'ヒミシノウギョウキヨウドウクミアイ' },
  { code: '5921', nameKanji: 'となみ野農業協同組合', nameKatakana: 'トナミノノウギョウキヨウドウクミアイ' },
  { code: '5927', nameKanji: 'なんと農業協同組合', nameKatakana: 'ナントノウギョウキヨウドウクミアイ' },
  { code: '5932', nameKanji: 'いなば農業協同組合', nameKatakana: 'イナバノウギョウキヨウドウクミアイ' },
  { code: '5935', nameKanji: '福光農業協同組合', nameKatakana: 'フクミツノウギョウキヨウドウクミアイ' },
  { code: '5943', nameKanji: '加賀農業協同組合', nameKatakana: 'カガノウギョウキヨウドウクミアイ' },
  { code: '5962', nameKanji: '小松市農業協同組合', nameKatakana: 'コマツシノウギョウキヨウドウクミアイ' },
  { code: '5980', nameKanji: '根上農業協同組合', nameKatakana: 'ネアガリノウギョウキヨウドウクミアイ' },
  { code: '5982', nameKanji: '能美農業協同組合', nameKatakana: 'ノミノウギョウキヨウドウクミアイ' },
  { code: '5997', nameKanji: '松任市農業協同組合', nameKatakana: 'マツトウシノウギョウキヨウドウクミアイ' },
  { code: '6010', nameKanji: '野々市農業協同組合', nameKatakana: 'ノノイチノウギョウキヨウドウクミアイ' },
  { code: '6012', nameKanji: '白山農業協同組合', nameKatakana: 'ハクサンノウギョウキヨウドウクミアイ' },
  { code: '6024', nameKanji: '金沢中央農業協同組合', nameKatakana: 'カナザワチユウオウノウギョウキヨウドウクミアイ' },
  { code: '6025', nameKanji: '金沢市農業協同組合', nameKatakana: 'カナザワシノウギョウキヨウドウクミアイ' },
  { code: '6062', nameKanji: '石川かほく農業協同組合', nameKatakana: 'イシカワカホクノウギョウキヨウドウクミアイ' },
  { code: '6076', nameKanji: 'はくい農業協同組合', nameKatakana: 'ハクイノウギョウキヨウドウクミアイ' },
  { code: '6084', nameKanji: '志賀農業協同組合', nameKatakana: 'シカノウギョウキヨウドウクミアイ' },
  { code: '6094', nameKanji: '能登わかば農業協同組合', nameKatakana: 'ノトワカバノウギョウキヨウドウクミアイ' },
  { code: '6113', nameKanji: '能登農業協同組合', nameKatakana: 'ノトノウギョウキヨウドウクミアイ' },
  { code: '6121', nameKanji: '内浦町農業協同組合', nameKatakana: 'ウチウラマチノウギョウキヨウドウクミアイ' },
  { code: '6129', nameKanji: 'ぎふ農業協同組合', nameKatakana: 'ギフノウギョウキヨウドウクミアイ' },
  { code: '6175', nameKanji: '西美濃農業協同組合', nameKatakana: 'ニシミノノウギョウキヨウドウクミアイ' },
  { code: '6198', nameKanji: 'いび川農業協同組合', nameKatakana: 'イビガワノウギョウキヨウドウクミアイ' },
  { code: '6242', nameKanji: 'めぐみの農業協同組合', nameKatakana: 'メグミノノウギョウキヨウドウクミアイ' },
  { code: '6265', nameKanji: '陶都信用農業協同組合', nameKatakana: 'トウトシンヨウノウギョウキヨウドウクミアイ' },
  { code: '6287', nameKanji: '東美濃農業協同組合', nameKatakana: 'ヒガシミノノウギョウキヨウドウクミアイ' },
  { code: '6313', nameKanji: '飛騨農業協同組合', nameKatakana: 'ヒダノウギョウキヨウドウクミアイ' },
  { code: '6345', nameKanji: '富士伊豆農業協同組合', nameKatakana: 'フジイズノウギョウキヨウドウクミアイ' },
  { code: '6363', nameKanji: '清水農業協同組合', nameKatakana: 'シミズノウギョウキヨウドウクミアイ' },
  { code: '6373', nameKanji: '静岡市農業協同組合', nameKatakana: 'シズオカシノウギョウキヨウドウクミアイ' },
  { code: '6377', nameKanji: '大井川農業協同組合', nameKatakana: 'オオイガワノウギョウキヨウドウクミアイ' },
  { code: '6382', nameKanji: 'ハイナン農業協同組合', nameKatakana: 'ハイナンノウギョウキヨウドウクミアイ' },
  { code: '6386', nameKanji: '掛川市農業協同組合', nameKatakana: 'カケガワシノウギョウキヨウドウクミアイ' },
  { code: '6387', nameKanji: '遠州夢咲農業協同組合', nameKatakana: 'エンシユウユメサキノウギョウキヨウドウクミアイ' },
  { code: '6391', nameKanji: '遠州中央農業協同組合', nameKatakana: 'エンシユウチユウオウノウギョウキヨウドウクミアイ' },
  { code: '6403', nameKanji: 'とぴあ浜松農業協同組合', nameKatakana: 'トピアハママツノウギョウキヨウドウクミアイ' },
  { code: '6423', nameKanji: '三ヶ日町農業協同組合', nameKatakana: 'ミツカビチヨウノウギョウキヨウドウクミアイ' },
  { code: '6430', nameKanji: 'なごや農業協同組合', nameKatakana: 'ナゴヤノウギョウキヨウドウクミアイ' },
  { code: '6436', nameKanji: '天白信用農業協同組合', nameKatakana: 'テンパクシンヨウノウギョウキヨウドウクミアイ' },
  { code: '6443', nameKanji: '緑信用農業協同組合', nameKatakana: 'ミドリシンヨウノウギョウキヨウドウクミアイ' },
  { code: '6451', nameKanji: '尾張中央農業協同組合', nameKatakana: 'オワリチユウオウノウギョウキヨウドウクミアイ' },
  { code: '6456', nameKanji: '西春日井農業協同組合', nameKatakana: 'ニシカスガイノウギョウキヨウドウクミアイ' },
  { code: '6466', nameKanji: 'あいち尾東農業協同組合', nameKatakana: 'アイチビトウノウギョウキヨウドウクミアイ' },
  { code: '6470', nameKanji: '愛知北農業協同組合', nameKatakana: 'アイチキタノウギョウキヨウドウクミアイ' },
  { code: '6483', nameKanji: '愛知西農業協同組合', nameKatakana: 'アイチニシノウギョウキヨウドウクミアイ' },
  { code: '6503', nameKanji: '海部東農業協同組合', nameKatakana: 'アマヒガシノウギョウキヨウドウクミアイ' },
  { code: '6514', nameKanji: 'あいち海部農業協同組合', nameKatakana: 'アイチアマノウギョウキヨウドウクミアイ' },
  { code: '6531', nameKanji: 'あいち知多農業協同組合', nameKatakana: 'アイチチタノウギョウキヨウドウクミアイ' },
  { code: '6552', nameKanji: 'あいち中央農業協同組合', nameKatakana: 'アイチチユウオウノウギョウキヨウドウクミアイ' },
  { code: '6560', nameKanji: '西三河農業協同組合', nameKatakana: 'ニシミカワノウギョウキヨウドウクミアイ' },
  { code: '6572', nameKanji: 'あいち三河農業協同組合', nameKatakana: 'アイチミカワノウギョウキヨウドウクミアイ' },
  { code: '6582', nameKanji: 'あいち豊田農業協同組合', nameKatakana: 'アイチトヨタノウギョウキヨウドウクミアイ' },
  { code: '6591', nameKanji: '愛知東農業協同組合', nameKatakana: 'アイチヒガシノウギョウキヨウドウクミアイ' },
  { code: '6606', nameKanji: '蒲郡市農業協同組合', nameKatakana: 'ガマゴオリシノウギョウキヨウドウクミアイ' },
  { code: '6612', nameKanji: 'ひまわり農業協同組合', nameKatakana: 'ヒマワリノウギョウキヨウドウクミアイ' },
  { code: '6615', nameKanji: '愛知みなみ農業協同組合', nameKatakana: 'アイチミナミノウギョウキヨウドウクミアイ' },
  { code: '6618', nameKanji: '豊橋農業協同組合', nameKatakana: 'トヨハシノウギョウキヨウドウクミアイ' },
  { code: '6649', nameKanji: '三重北農業協同組合', nameKatakana: 'ミエキタノウギョウキヨウドウクミアイ' },
  { code: '6665', nameKanji: '鈴鹿農業協同組合', nameKatakana: 'スズカノウギョウキヨウドウクミアイ' },
  { code: '6673', nameKanji: '津安芸農業協同組合', nameKatakana: 'ツアゲノウギョウキヨウドウクミアイ' },
  { code: '6677', nameKanji: 'みえなか農業協同組合', nameKatakana: 'ミエナカノウギョウキヨウドウクミアイ' },
  { code: '6697', nameKanji: '多気郡農業協同組合', nameKatakana: 'タキグンノウギョウキヨウドウクミアイ' },
  { code: '6731', nameKanji: '伊勢農業協同組合', nameKatakana: 'イセノウギョウキヨウドウクミアイ' },
  { code: '6758', nameKanji: '伊賀ふるさと農業協同組合', nameKatakana: 'イガフルサトノウギョウキヨウドウクミアイ' },
  { code: '6785', nameKanji: '福井県農業協同組合', nameKatakana: 'フクイケンノウギョウキヨウドウクミアイ' },
  { code: '6853', nameKanji: '越前たけふ農業協同組合', nameKatakana: 'エチゼンタケフノウギョウキヨウドウクミアイ' },
  { code: '6874', nameKanji: 'レーク滋賀農業協同組合', nameKatakana: 'レ－クシガノウギョウキヨウドウクミアイ' },
  { code: '6889', nameKanji: '甲賀農業協同組合', nameKatakana: 'コウカノウギョウキヨウドウクミアイ' },
  { code: '6897', nameKanji: 'グリーン近江農業協同組合', nameKatakana: 'グリ－ンオウミノウギョウキヨウドウクミアイ' },
  { code: '6900', nameKanji: '滋賀蒲生町農業協同組合', nameKatakana: 'シガガモウチヨウノウギョウキヨウドウクミアイ' },
  { code: '6909', nameKanji: '東能登川農業協同組合', nameKatakana: 'ヒガシノトガワノウギョウキヨウドウクミアイ' },
  { code: '6911', nameKanji: '湖東農業協同組合', nameKatakana: 'コトウノウギョウキヨウドウクミアイ' },
  { code: '6912', nameKanji: '東びわこ農業協同組合', nameKatakana: 'ヒガシビワコノウギョウキヨウドウクミアイ' },
  { code: '6919', nameKanji: 'レーク伊吹農業協同組合', nameKatakana: 'レ－クイブキノウギョウキヨウドウクミアイ' },
  { code: '6924', nameKanji: '北びわこ農業協同組合', nameKatakana: 'キタビワコノウギョウキヨウドウクミアイ' },
  { code: '6941', nameKanji: '京都市農業協同組合', nameKatakana: 'キヨウトシノウギョウキヨウドウクミアイ' },
  { code: '6956', nameKanji: '京都中央農業協同組合', nameKatakana: 'キヨウトチユウオウノウギョウキヨウドウクミアイ' },
  { code: '6961', nameKanji: '京都やましろ農業協同組合', nameKatakana: 'キヨウトヤマシロノウギョウキヨウドウクミアイ' },
  { code: '6990', nameKanji: '京都農業協同組合', nameKatakana: 'キヨウトノウギョウキヨウドウクミアイ' },
  { code: '6996', nameKanji: '京都丹の国農業協同組合', nameKatakana: 'キヨウトニノクニノウギョウキヨウドウクミアイ' },
  { code: '7025', nameKanji: '北大阪農業協同組合', nameKatakana: 'キタオオサカノウギョウキヨウドウクミアイ' },
  { code: '7029', nameKanji: '高槻市農業協同組合', nameKatakana: 'タカツキシノウギョウキヨウドウクミアイ' },
  { code: '7032', nameKanji: '茨木市農業協同組合', nameKatakana: 'イバラキシノウギョウキヨウドウクミアイ' },
  { code: '7041', nameKanji: '大阪北部農業協同組合', nameKatakana: 'オオサカホクブノウギョウキヨウドウクミアイ' },
  { code: '7087', nameKanji: '大阪泉州農業協同組合', nameKatakana: 'オオサカセンシユウノウギョウキヨウドウクミアイ' },
  { code: '7092', nameKanji: 'いずみの農業協同組合', nameKatakana: 'イズミノノウギョウキヨウドウクミアイ' },
  { code: '7111', nameKanji: '堺市農業協同組合', nameKatakana: 'サカイシノウギョウキヨウドウクミアイ' },
  { code: '7139', nameKanji: '大阪南農業協同組合', nameKatakana: 'オオサカミナミノウギョウキヨウドウクミアイ' },
  { code: '7156', nameKanji: 'グリーン大阪農業協同組合', nameKatakana: 'グリ－ンオオサカノウギョウキヨウドウクミアイ' },
  { code: '7164', nameKanji: '大阪中河内農業協同組合', nameKatakana: 'オオサカナカカワチノウギョウキヨウドウクミアイ' },
  { code: '7184', nameKanji: '大阪東部農業協同組合', nameKatakana: 'オオサカトウブノウギョウキヨウドウクミアイ' },
  { code: '7191', nameKanji: '九個荘農業協同組合', nameKatakana: 'クカシヨウノウギョウキヨウドウクミアイ' },
  { code: '7193', nameKanji: '北河内農業協同組合', nameKatakana: 'キタカワチノウギョウキヨウドウクミアイ' },
  { code: '7200', nameKanji: '大阪市農業協同組合', nameKatakana: 'オオサカシノウギョウキヨウドウクミアイ' },
  { code: '7213', nameKanji: '兵庫六甲農業協同組合', nameKatakana: 'ヒヨウゴロツコウノウギョウキヨウドウクミアイ' },
  { code: '7239', nameKanji: 'あかし農業協同組合', nameKatakana: 'アカシノウギョウキヨウドウクミアイ' },
  { code: '7240', nameKanji: '兵庫南農業協同組合', nameKatakana: 'ヒヨウゴミナミノウギョウキヨウドウクミアイ' },
  { code: '7249', nameKanji: 'みのり農業協同組合', nameKatakana: 'ミノリノウギョウキヨウドウクミアイ' },
  { code: '7264', nameKanji: '兵庫みらい農業協同組合', nameKatakana: 'ヒヨウゴミライノウギョウキヨウドウクミアイ' },
  { code: '7274', nameKanji: '加古川市南農業協同組合', nameKatakana: 'カコガワシミナミノウギョウキヨウドウクミアイ' },
  { code: '7288', nameKanji: '兵庫西農業協同組合', nameKatakana: 'ヒヨウゴニシノウギョウキヨウドウクミアイ' },
  { code: '7316', nameKanji: '相生市農業協同組合', nameKatakana: 'アイオイシノウギョウキヨウドウクミアイ' },
  { code: '7326', nameKanji: 'ハリマ農業協同組合', nameKatakana: 'ハリマノウギョウキヨウドウクミアイ' },
  { code: '7338', nameKanji: 'たじま農業協同組合', nameKatakana: 'タジマノウギョウキヨウドウクミアイ' },
  { code: '7353', nameKanji: '丹波ひかみ農業協同組合', nameKatakana: 'タンバヒカミノウギョウキヨウドウクミアイ' },
  { code: '7362', nameKanji: '丹波ささやま農業協同組合', nameKatakana: 'タンバササヤマノウギョウキヨウドウクミアイ' },
  { code: '7363', nameKanji: '淡路日の出農業協同組合', nameKatakana: 'アワジヒノデノウギョウキヨウドウクミアイ' },
  { code: '7373', nameKanji: 'あわじ島農業協同組合', nameKatakana: 'アワジシマノウギョウキヨウドウクミアイ' },
  { code: '7387', nameKanji: '奈良県農業協同組合', nameKatakana: 'ナラケンノウギョウキヨウドウクミアイ' },
  { code: '7532', nameKanji: 'わかやま農業協同組合', nameKatakana: 'ワカヤマノウギョウキヨウドウクミアイ' },
  { code: '7541', nameKanji: 'ながみね農業協同組合', nameKatakana: 'ナガミネノウギョウキヨウドウクミアイ' },
  { code: '7543', nameKanji: '紀の里農業協同組合', nameKatakana: 'キノサトノウギョウキヨウドウクミアイ' },
  { code: '7550', nameKanji: '紀北川上農業協同組合', nameKatakana: 'キホクカワカミノウギョウキヨウドウクミアイ' },
  { code: '7559', nameKanji: 'ありだ農業協同組合', nameKatakana: 'アリダノウギョウキヨウドウクミアイ' },
  { code: '7565', nameKanji: '紀州農業協同組合', nameKatakana: 'キシユウノウギョウキヨウドウクミアイ' },
  { code: '7576', nameKanji: '紀南農業協同組合', nameKatakana: 'キナンノウギョウキヨウドウクミアイ' },
  { code: '7591', nameKanji: 'みくまの農業協同組合', nameKatakana: 'ミクマノノウギョウキヨウドウクミアイ' },
  { code: '7601', nameKanji: '鳥取いなば農業協同組合', nameKatakana: 'トツトリイナバノウギョウキヨウドウクミアイ' },
  { code: '7625', nameKanji: '鳥取中央農業協同組合', nameKatakana: 'トツトリチユウオウノウギョウキヨウドウクミアイ' },
  { code: '7641', nameKanji: '鳥取西部農業協同組合', nameKatakana: 'トツトリセイブノウギョウキヨウドウクミアイ' },
  { code: '7708', nameKanji: '島根県農業協同組合', nameKatakana: 'シマネケンノウギョウキヨウドウクミアイ' },
  { code: '7755', nameKanji: '岡山市農業協同組合', nameKatakana: 'オカヤマシノウギョウキヨウドウクミアイ' },
  { code: '7837', nameKanji: '晴れの国岡山農業協同組合', nameKatakana: 'ハレノクニオカヤマノウギョウキヨウドウクミアイ' },
  { code: '7909', nameKanji: '広島市農業協同組合', nameKatakana: 'ヒロシマシノウギョウキヨウドウクミアイ' },
  { code: '7994', nameKanji: 'ひろしま農業協同組合', nameKatakana: 'ヒロシマノウギョウキヨウドウクミアイ' },
  { code: '8019', nameKanji: '広島ゆたか農業協同組合', nameKatakana: 'ヒロシマユタカノウギョウキヨウドウクミアイ' },
  { code: '8029', nameKanji: '尾道市農業協同組合', nameKatakana: 'オノミチシノウギョウキヨウドウクミアイ' },
  { code: '8047', nameKanji: '福山市農業協同組合', nameKatakana: 'フクヤマシノウギョウキヨウドウクミアイ' },
  { code: '8134', nameKanji: '山口県農業協同組合', nameKatakana: 'ヤマグチケンノウギョウキヨウドウクミアイ' },
  { code: '8231', nameKanji: '徳島市農業協同組合', nameKatakana: 'トクシマシノウギョウキヨウドウクミアイ' },
  { code: '8234', nameKanji: '東とくしま農業協同組合', nameKatakana: 'ヒガシトクシマノウギョウキヨウドウクミアイ' },
  { code: '8252', nameKanji: '徳島県農業協同組合', nameKatakana: 'トクシマケンノウギョウキヨウドウクミアイ' },
  { code: '8261', nameKanji: '大津松茂農業協同組合', nameKatakana: 'オオツマツシゲノウギョウキヨウドウクミアイ' },
  { code: '8263', nameKanji: '里浦農業協同組合', nameKatakana: 'サトウラノウギョウキヨウドウクミアイ' },
  { code: '8300', nameKanji: '市場町農業協同組合', nameKatakana: 'イチバチヨウノウギョウキヨウドウクミアイ' },
  { code: '8301', nameKanji: '阿波郡東部農業協同組合', nameKatakana: 'アワグントウブノウギョウキヨウドウクミアイ' },
  { code: '8332', nameKanji: '香川県農業協同組合', nameKatakana: 'カガワケンノウギョウキヨウドウクミアイ' },
  { code: '8389', nameKanji: 'うま農業協同組合', nameKatakana: 'ウマノウギョウキヨウドウクミアイ' },
  { code: '8397', nameKanji: 'えひめ未来農業協同組合', nameKatakana: 'エヒメミライノウギョウキヨウドウクミアイ' },
  { code: '8398', nameKanji: '周桑農業協同組合', nameKatakana: 'シユウソウノウギョウキヨウドウクミアイ' },
  { code: '8400', nameKanji: '越智今治農業協同組合', nameKatakana: 'オチイマバリノウギョウキヨウドウクミアイ' },
  { code: '8401', nameKanji: '今治立花農業協同組合', nameKatakana: 'イマバリタチバナノウギョウキヨウドウクミアイ' },
  { code: '8425', nameKanji: '松山市農業協同組合', nameKatakana: 'マツヤマシノウギョウキヨウドウクミアイ' },
  { code: '8457', nameKanji: '愛媛たいき農業協同組合', nameKatakana: 'エヒメタイキノウギョウキヨウドウクミアイ' },
  { code: '8463', nameKanji: '西宇和農業協同組合', nameKatakana: 'ニシウワノウギョウキヨウドウクミアイ' },
  { code: '8477', nameKanji: '東宇和農業協同組合', nameKatakana: 'ヒガシウワノウギョウキヨウドウクミアイ' },
  { code: '8482', nameKanji: 'えひめ南農業協同組合', nameKatakana: 'エヒメミナミノウギョウキヨウドウクミアイ' },
  { code: '8500', nameKanji: 'えひめ中央農業協同組合', nameKatakana: 'エヒメチユウオウノウギョウキヨウドウクミアイ' },
  { code: '8551', nameKanji: '高知市農業協同組合', nameKatakana: 'コウチシノウギョウキヨウドウクミアイ' },
  { code: '8582', nameKanji: '高知県農業協同組合', nameKatakana: 'コウチケンノウギョウキヨウドウクミアイ' },
  { code: '8589', nameKanji: '土佐くろしお農業協同組合', nameKatakana: 'トサクロシオノウギョウキヨウドウクミアイ' },
  { code: '8621', nameKanji: '宗像農業協同組合', nameKatakana: 'ムナカタノウギョウキヨウドウクミアイ' },
  { code: '8626', nameKanji: '粕屋農業協同組合', nameKatakana: 'カスヤノウギョウキヨウドウクミアイ' },
  { code: '8632', nameKanji: '福岡市東部農業協同組合', nameKatakana: 'フクオカシトウブノウギョウキヨウドウクミアイ' },
  { code: '8633', nameKanji: '福岡市農業協同組合', nameKatakana: 'フクオカシノウギョウキヨウドウクミアイ' },
  { code: '8635', nameKanji: '糸島農業協同組合', nameKatakana: 'イトシマノウギョウキヨウドウクミアイ' },
  { code: '8636', nameKanji: '筑紫農業協同組合', nameKatakana: 'チクシノウギョウキヨウドウクミアイ' },
  { code: '8645', nameKanji: '筑前あさくら農業協同組合', nameKatakana: 'チクゼンアサクラノウギョウキヨウドウクミアイ' },
  { code: '8653', nameKanji: 'にじ農業協同組合', nameKatakana: 'ニジノウギョウキヨウドウクミアイ' },
  { code: '8656', nameKanji: 'みい農業協同組合', nameKatakana: 'ミイノウギョウキヨウドウクミアイ' },
  { code: '8660', nameKanji: '久留米市農業協同組合', nameKatakana: 'クルメシノウギョウキヨウドウクミアイ' },
  { code: '8664', nameKanji: '三潴町農業協同組合', nameKatakana: 'ミズママチノウギョウキヨウドウクミアイ' },
  { code: '8667', nameKanji: '福岡大城農業協同組合', nameKatakana: 'フクオカオオキノウギョウキヨウドウクミアイ' },
  { code: '8668', nameKanji: '福岡八女農業協同組合', nameKatakana: 'フクオカヤメノウギョウキヨウドウクミアイ' },
  { code: '8680', nameKanji: '柳川農業協同組合', nameKatakana: 'ヤナガワノウギョウキヨウドウクミアイ' },
  { code: '8689', nameKanji: '南筑後農業協同組合', nameKatakana: 'ミナミチクゴノウギョウキヨウドウクミアイ' },
  { code: '8692', nameKanji: '北九州農業協同組合', nameKatakana: 'キタキユウシユウノウギョウキヨウドウクミアイ' },
  { code: '8694', nameKanji: '直鞍農業協同組合', nameKatakana: 'チヨクアンノウギョウキヨウドウクミアイ' },
  { code: '8701', nameKanji: '福岡嘉穂農業協同組合', nameKatakana: 'フクオカカホノウギョウキヨウドウクミアイ' },
  { code: '8715', nameKanji: '田川農業協同組合', nameKatakana: 'タガワノウギョウキヨウドウクミアイ' },
  { code: '8730', nameKanji: '福岡京築農業協同組合', nameKatakana: 'フクオカケイチクノウギョウキヨウドウクミアイ' },
  { code: '8740', nameKanji: '佐賀市中央農業協同組合', nameKatakana: 'サガシチユウオウノウギョウキヨウドウクミアイ' },
  { code: '8762', nameKanji: '佐賀県農業協同組合', nameKatakana: 'サガケンノウギョウキヨウドウクミアイ' },
  { code: '8766', nameKanji: '唐津農業協同組合', nameKatakana: 'カラツノウギョウキヨウドウクミアイ' },
  { code: '8771', nameKanji: '伊万里市農業協同組合', nameKatakana: 'イマリシノウギョウキヨウドウクミアイ' },
  { code: '8794', nameKanji: '長崎西彼農業協同組合', nameKatakana: 'ナガサキセイヒノウギョウキヨウドウクミアイ' },
  { code: '8813', nameKanji: '長崎県央農業協同組合', nameKatakana: 'ナガサキケンオウノウギョウキヨウドウクミアイ' },
  { code: '8829', nameKanji: '島原雲仙農業協同組合', nameKatakana: 'シマバラウンゼンノウギョウキヨウドウクミアイ' },
  { code: '8857', nameKanji: 'ながさき西海農業協同組合', nameKatakana: 'ナガサキサイカイノウギョウキヨウドウクミアイ' },
  { code: '8893', nameKanji: 'ごとう農業協同組合', nameKatakana: 'ゴトウノウギョウキヨウドウクミアイ' },
  { code: '8905', nameKanji: '壱岐市農業協同組合', nameKatakana: 'イキシノウギョウキヨウドウクミアイ' },
  { code: '8906', nameKanji: '対馬農業協同組合', nameKatakana: 'ツシマノウギョウキヨウドウクミアイ' },
  { code: '8916', nameKanji: '熊本市農業協同組合', nameKatakana: 'クマモトシノウギョウキヨウドウクミアイ' },
  { code: '8926', nameKanji: '玉名農業協同組合', nameKatakana: 'タマナノウギョウキヨウドウクミアイ' },
  { code: '8941', nameKanji: '鹿本農業協同組合', nameKatakana: 'カモトノウギョウキヨウドウクミアイ' },
  { code: '8949', nameKanji: '菊池地域農業協同組合', nameKatakana: 'キクチチイキノウギョウキヨウドウクミアイ' },
  { code: '8964', nameKanji: '阿蘇農業協同組合', nameKatakana: 'アソノウギョウキヨウドウクミアイ' },
  { code: '8982', nameKanji: '上益城農業協同組合', nameKatakana: 'カミマシキノウギョウキヨウドウクミアイ' },
  { code: '9010', nameKanji: '熊本宇城農業協同組合', nameKatakana: 'クマモトウキノウギョウキヨウドウクミアイ' },
  { code: '9017', nameKanji: '八代地域農業協同組合', nameKatakana: 'ヤツシロチイキノウギョウキヨウドウクミアイ' },
  { code: '9043', nameKanji: 'あしきた農業協同組合', nameKatakana: 'アシキタノウギョウキヨウドウクミアイ' },
  { code: '9048', nameKanji: '球磨地域農業協同組合', nameKatakana: 'クマチイキノウギョウキヨウドウクミアイ' },
  { code: '9069', nameKanji: '本渡五和農業協同組合', nameKatakana: 'ホンドイツワノウギョウキヨウドウクミアイ' },
  { code: '9070', nameKanji: 'あまくさ農業協同組合', nameKatakana: 'アマクサノウギョウキヨウドウクミアイ' },
  { code: '9072', nameKanji: '苓北町農業協同組合', nameKatakana: 'レイホクマチノウギョウキヨウドウクミアイ' },
  { code: '9103', nameKanji: 'べっぷ日出農業協同組合', nameKatakana: 'ベツプヒジノウギョウキヨウドウクミアイ' },
  { code: '9104', nameKanji: '大分県農業協同組合', nameKatakana: 'オオイタケンノウギョウキヨウドウクミアイ' },
  { code: '9145', nameKanji: '大分大山町農業協同組合', nameKatakana: 'オオイタオオヤママチノウギョウキヨウドウクミアイ' },
  { code: '9169', nameKanji: '宮崎県農業協同組合', nameKatakana: 'ミヤザキケンノウギョウキヨウドウクミアイ' },
  { code: '9229', nameKanji: '鹿児島みらい農業協同組合', nameKatakana: 'カゴシマミライノウギョウキヨウドウクミアイ' },
  { code: '9251', nameKanji: 'いぶすき農業協同組合', nameKatakana: 'イブスキノウギョウキヨウドウクミアイ' },
  { code: '9257', nameKanji: '南さつま農業協同組合', nameKatakana: 'ミナミサツマノウギョウキヨウドウクミアイ' },
  { code: '9270', nameKanji: 'さつま日置農業協同組合', nameKatakana: 'サツマヒオキノウギョウキヨウドウクミアイ' },
  { code: '9296', nameKanji: '北さつま農業協同組合', nameKatakana: 'キタサツマノウギョウキヨウドウクミアイ' },
  { code: '9302', nameKanji: '鹿児島いずみ農業協同組合', nameKatakana: 'カゴシマイズミノウギョウキヨウドウクミアイ' },
  { code: '9319', nameKanji: 'あいら農業協同組合', nameKatakana: 'アイラノウギョウキヨウドウクミアイ' },
  { code: '9332', nameKanji: 'そお鹿児島農業協同組合', nameKatakana: 'ソオカゴシマノウギョウキヨウドウクミアイ' },
  { code: '9338', nameKanji: 'あおぞら農業協同組合', nameKatakana: 'アオゾラノウギョウキヨウドウクミアイ' },
  { code: '9341', nameKanji: '鹿児島きもつき農業協同組合', nameKatakana: 'カゴシマキモツキノウギョウキヨウドウクミアイ' },
  { code: '9347', nameKanji: '肝付吾平町農業協同組合', nameKatakana: 'キモツキアイラチヨウノウギョウキヨウドウクミアイ' },
  { code: '9353', nameKanji: '種子屋久農業協同組合', nameKatakana: 'タネヤクノウギョウキヨウドウクミアイ' },
  { code: '9363', nameKanji: 'あまみ農業協同組合', nameKatakana: 'アマミノウギョウキヨウドウクミアイ' },
  { code: '9375', nameKanji: '沖縄県農業協同組合', nameKatakana: 'オキナワケンノウギョウキヨウドウクミアイ' },
  { code: '9450', nameKanji: '北海道信用漁業協同組合連合会', nameKatakana: 'ホツカイドウシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9456', nameKanji: '福島県信用漁業協同組合連合会', nameKatakana: 'フクシマケンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9461', nameKanji: '東日本信用漁業協同組合連合会', nameKatakana: 'ヒガシニホンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9475', nameKanji: '京都府信用漁業協同組合連合会', nameKatakana: 'キヨウトフシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9477', nameKanji: 'なぎさ信用漁業協同組合連合会', nameKatakana: 'ナギサシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9481', nameKanji: 'JFしまね漁協', nameKatakana: 'ジエイエフシマネギヨキヨウ' },
  { code: '9483', nameKanji: '広島県信用漁業協同組合連合会', nameKatakana: 'ヒロシマケンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9484', nameKanji: '山口県漁協', nameKatakana: 'ヤマグチケンギヨキヨウ' },
  { code: '9485', nameKanji: '徳島県信用漁業協同組合連合会', nameKatakana: 'トクシマケンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9486', nameKanji: '西日本信用漁業協同組合連合会', nameKatakana: 'ニシニホンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9487', nameKanji: '愛媛県信用漁業協同組合連合会', nameKatakana: 'エヒメケンシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9489', nameKanji: '九州信用漁業協同組合連合会', nameKatakana: 'キユウシユウシンヨウギヨギヨウキヨウリヨククミアイレンゴウカイ' },
  { code: '9493', nameKanji: '大分県漁協', nameKatakana: 'オオイタケンギヨキヨウ' },
  { code: '9900', nameKanji: 'ゆうちょ銀行', nameKatakana: 'ユウチヨギヨキヨウ' },
];
