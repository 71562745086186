import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DownloadOutlined, EyeTwoTone, SearchOutlined} from '@ant-design/icons';
import {
  Table, Input, Form, Button, Space, Tag, message,
} from 'antd';

import {request} from '@/utils';
import qs from 'qs';
import DetailModal from '@/components/DetailModal';

// 返回表格查询需要的参数格式
const App = () => {
  const navigate = useNavigate();
  // 表格参数
  const columns = [{
    dataIndex: 'id', title: '精算ID', width: 75,
  }, {
    dataIndex: 'storeName', title: '店舗名', width: 180, ellipsis: true,
  }, {
    dataIndex: 'tourName', title: 'ツアー名', width: 180,
  }, {
    dataIndex: 'visitDate', title: '入店日', width: 120,
  }, {
    dataIndex: 'paymentType', title: ' 精算種類 ', width: 100,
  }, {
    dataIndex: 'amount', title: '金額', width: 120, render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    },
  }, {
    dataIndex: 'carryOver', title: '繰越', width: 120, render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    },
  }, {
    dataIndex: 'paymentFee', title: '精算手数料', width: 120, render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    },
  }, {
    dataIndex: 'paymentDate', title: '精算日', width: 120,
  }, {
    dataIndex: 'payer', title: '精算者', ellipsis: true,
  }, {
    dataIndex: 'guideName', title: 'ガイド名', width: 120,
  }, {
    dataIndex: 'payee', title: '精算先', width: 120,
  }, {
    dataIndex: 'paymentStatus', title: '精算状態', width: 120, render: (data) => {
      if (data === '精算済み') {
        return <Tag color="success" style={{fontSize: '14px'}}>精算済み</Tag>;
      } else if (data === '精算待ち') {
        return <Tag color="warning" style={{fontSize: '14px'}}>精算待ち</Tag>;
      } else {
        return <Tag color="default" style={{fontSize: '14px'}}>{data}</Tag>;
      }
    },
  },

    {
      title: (<div>
        操作</div>), fixed: 'right', width: 60, render: (_, record) => {

        return (<Space size="middle">
          <Button icon={<EyeTwoTone />} onClick={() => handleDetailShow(record)} />
        </Space>);
      },
    }];

  const [dataRecord, setDataRecord] = useState({});
  // 传值表单
  const [form] = Form.useForm();

  // 列表配置
  const items = [{
    key: '1', label: '店舗名', name: 'storeName', children: dataRecord.storeName,
  }, {
    key: '2', label: 'ツアー名', name: 'tourName', children: dataRecord.tourName,
  }, {
    key: '3', label: '入店日', name: 'visitDate', children: dataRecord.visitDate,
  }, {
    key: '4', label: '精算種類', name: 'paymentType', children: dataRecord.paymentType,
  }, {
    key: '5', label: '金額', name: 'amount', children: dataRecord.amount,
  }, {
    key: '6', label: '繰越', name: 'carryOver', children: dataRecord.carryOver,
  }, {
    key: '7', label: '精算手数料', name: 'paymentFee', children: dataRecord.paymentFee,
  }, {
    key: '8', label: '精算日', name: 'paymentDate', children: dataRecord.paymentDate,
  }, {
    key: '9', label: '精算者', name: 'payer', children: dataRecord.payer,
  }, {
    key: '10', label: 'ガイド名', name: 'guideName', children: dataRecord.guideName,
  }, {
    key: '11', label: '精算先', name: 'payee', children: dataRecord.payee,
  }, {
    key: '12', label: '精算状態', name: 'paymentStatus', children: dataRecord.paymentStatus,
  }, {
    key: '13', label: '備考欄', name: 'remarks', children: dataRecord.remarks,
  }];

  const [loading, setLoading] = useState(false);

  // 详细模块可见状态
  const [detailVisible, setDetailVisible] = useState(false);

  // 删除
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleButtonClick = () => {

    const newFilters = {
      id, tourName,
    };
    console.log('newFilters:~~~', newFilters);

    setRequestData(prevTableParams => ({
      ...prevTableParams, ...newFilters,
    }));

    console.log('requestData:~~~', requestData);
  };

  const [total, setTotal] = useState(0);

  const [dataList, setDataList] = useState([{
    'id': '',
    'paymentType': '',
    'storeName': '',
    'tourName': '',
    'visitDate': '',
    'amount': '',
    'carryOver': '',
    'paymentFee': '',
    'paymentDate': '',
    'payer': '',
    'guideName': '',
    'payee': '',
    'paymentStatus': '',
    'remarks': '',
    'uploadAt': '',
    'isDeleted': false,
  }]);

  const [requestData, setRequestData] = useState({
    id: null,

    tourName: '',

    page: 0, seize: 10,
  });

  useEffect(() => {
    handleSearch();
  }, [requestData]);

  const handleSearch = async () => {
    try {
      // 将参数对象转换为查询字符串
      const queryString = qs.stringify(requestData);

      // 发送请求
      const response = await request.get(`/user/getUserActuarialData?${queryString}`);
      if (response.status === '200') {
        // 更新分页参数
        setDataList(response.data.actuarialList);
        setTotal(response.data.totalItems);
      } else if (response.status === '401' || response.message === '请先修改密码') {
        // 提示用户修改密码
        alert('パスワードを変更してください');

        // 使用 navigate 函数跳转到 /userSecond 页面
        navigate('/userSecond');
      }

    } catch (error) {
      console.error('Error searching data:', error);
    }
  };


  // 表格参数变化
  const handleTableChange = (newPageNum, newPageSize) => {
    setRequestData(prevTableParams => ({
      ...prevTableParams, current: newPageNum, pageSize: newPageSize,
    }));
  };

  // 点击详细按钮
  const handleDetailShow = (record) => {

    // 设置列表值
    setDataRecord(record);
    // 设置编辑表单值
    form.setFieldsValue({
      ...record,
    });
    setDetailVisible(true);
  };

  // 检索条件输入
  const [id, setId] = useState('');
  const [tourName, setTourName] = useState('');

  const idInputChange = (e) => {
    setId(e.target.value);
  };

  const tourNameInputChange = (e) => {
    setTourName(e.target.value);
  };
  const handleDetailClose = () => {
    setDetailVisible(false); // 关闭确认弹窗

  };

  //导出excel
  const exportExcel = async () => {
    setLoading(true);
    let url;
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      url = `/user/downloadActuarialExcel?ids=${selectedRowKeys.join(',')}`;
    } else {
      const queryString = qs.stringify(requestData);
      url = `/user/downloadActuarialExcel?${queryString}`;
    }

    await request.get(url, {
      responseType: 'blob', // 确保响应类型为 blob
    }).then(res => {
      // 创建下载的链接
      const url = window.URL.createObjectURL(new Blob([res], // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const link = document.createElement('a');
      link.href = url;
      // 从header中获取服务端命名的文件名
      const fileName = '1213';
      link.setAttribute('download', '精算リスト.xlsx');
      document.body.appendChild(link);
      link.click();
      message.success('発行成功');
    });
    setLoading(false);
  };

  return (<div>
    <div style={{marginBottom: 16}}>
      <span>ID検索:</span>
      <Input placeholder="100" onChange={idInputChange} style={{
        width: '10%', marginRight: '20px', marginLeft: '5px',
      }} />
      <span>ツアー名検索:</span>
      <Input placeholder="KMM-X-240623SZSH-Q" onChange={tourNameInputChange} style={{
        width: '15%', marginRight: '20px', marginLeft: '5px',
      }} />
      <Button onClick={handleButtonClick} style={{
        marginRight: '30px',
      }} type="primary" icon={<SearchOutlined />}>検索</Button>
      <Button onClick={() => exportExcel()} style={{marginRight: '20px'}} type="primary" icon={<DownloadOutlined />}
      > ダウンロード
      </Button>
    </div>

    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={dataList}
      //pagination={requestData.pagination}
      pagination={{
        current: requestData.page + 1, pageSize: requestData.size, total: total, // 确保你有 totalRecords 变量表示总记录数
        onChange: (page, pageSize) => {
          setRequestData({
            ...requestData, page: page + 1, size: pageSize,
          });

        },
      }}
      loading={loading}
      onChange={handleTableChange}
      // 设置多选
      rowSelection={{
        fixed: 'left', // type: 'checkbox',
        columnWidth: 25, selectedRowKeys, onChange: (selectedRowKeys) => {
          console.log(selectedRowKeys);
          setSelectedRowKeys(selectedRowKeys);
        }, preserveSelectedRowKeys: true,
      }}
    />
    <DetailModal
      detailVisible={detailVisible}
      handleDetailClose={handleDetailClose}
      items={items}
    />

  </div>);
};
export default App;