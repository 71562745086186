import React from 'react';
import {Modal} from 'antd';
import './ActuarialDetailsModal.css'; // 引入自定义样式

const ActuarialDetailsModal = ({visible, onCancel, actuarialDetails}) => {

  const formatNumber = (number) => new Intl.NumberFormat('ja-JP').format(number);
  const isNegative = (number) => number < 0;

  return (<Modal
    visible={visible}
    title="精算金額詳細(精算待ち)"
    onCancel={onCancel}
    footer={null}
  >
    <div className="actuarial-details">
      <div className="actuarial-item">
        <span className="actuarial-label">ドリームジャパン（株）：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.dmjpTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.dmjpTotal)}
          </span>
      </div>
      <div className="actuarial-item">
        <span className="actuarial-label">カモメツーリスト（株）：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.kmmTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.kmmTotal)}
          </span>
      </div>
      <div className="actuarial-item">
        <span className="actuarial-label">（株）ワンズライフジャパン：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.onesTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.onesTotal)}
          </span>
      </div>
      <div className="actuarial-item">
        <span className="actuarial-label">ＪＴＭホールディングス（株）：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.jtmhTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.jtmhTotal)}
          </span>
      </div>
      <div className="actuarial-item additional-spacing">
        <span className="actuarial-label">その他会社合計：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.otherTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.otherTotal)}
          </span>
      </div>
      <div className="actuarial-item">
        <span className="actuarial-label">総決算金額(精算待ち)：</span>
        <span className={`actuarial-content ${isNegative(actuarialDetails.finalTotal) ? 'negative' : 'positive'}`}>
            ￥{formatNumber(actuarialDetails.finalTotal)}
          </span>
      </div>
    </div>
  </Modal>);
};

export default ActuarialDetailsModal;