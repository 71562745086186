import React from 'react';
import {Modal, Descriptions} from 'antd';

const DetailModal = ({detailVisible, handleDetailClose, items}) => {
  const styledItems = items.map(item => {
    let contentStyle = {fontWeight: 'bold'};
    let children = item.children;

    if (['金額', '繰越', '精算手数料'].includes(item.label)) {
      const amount = parseFloat(children);
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      children = `￥${formattedAmount}`;
      if (amount < 0) {
        contentStyle.color = 'red';
      }
    }

    if (item.label === '精算状態') {
      if (item.children === '支払済み') {
        contentStyle.color = 'green';
      } else if (item.children === '支払待ち') {
        contentStyle.color = 'red';
      }
    }

    return {
      ...item, contentStyle, children,
    };
  });

  return (<Modal
    title="詳細情報"
    open={detailVisible}
    onOk={handleDetailClose}
    onCancel={handleDetailClose}
    footer={null}
    width={1000}
  >
    <Descriptions
      size="small"
      layout="vertical"
      bordered
      column={{
        xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
      }}
    >
      {styledItems.map(item => (<Descriptions.Item
        key={item.key}
        label={item.label}
        labelStyle={{fontWeight: 'bold'}}
        contentStyle={item.contentStyle}
      >
        {item.children}
      </Descriptions.Item>))}
    </Descriptions>
  </Modal>);
};

export default DetailModal;
