import React from 'react';
import {MailOutlined} from '@ant-design/icons';
import {Button, Popconfirm, message, notification} from 'antd';
import {request} from '@/utils';
import qs from 'qs';

const SendMailButtonForHome = ({selectedActuarialIds, total, requestData}) => {

  const handleSendMail = async () => {
    const ActuarialIds = selectedActuarialIds.length > 0 ? selectedActuarialIds : [];
    const {id, paymentType, storeName, paymentStatus, guideName, payee, payer, tourName, userId} = requestData;

    // 检查是否所有请求参数为空
    const areQueryParamsEmpty = !id && !paymentType && !storeName && !paymentStatus && !guideName && !payee && !payer && !tourName && !userId;

    if (ActuarialIds.length === 0 && areQueryParamsEmpty) {
      message.warning('精算IDを選択又は検索条件を入力してください');
      return;
    }

    try {
      // 构建查询参数字符串
      const queryString = qs.stringify({
        id, paymentType, storeName, paymentStatus, guideName, payee, payer, tourName, userId,
      });
      const url = `/admin/sendMailByActuarialIds?${queryString}`;

      const response = await request.post(url, ActuarialIds);

      if (response.status === '200') {
        message.success('メール送信成功');
      } else {
        notification.error({
          message: 'Error', description: response.message, duration: 0, // 设为0表示不会自动消失
          onClose: () => console.log('メール送信失敗'),
        });
      }
    } catch (error) {
      message.error('メール送信失敗: ' + (error.response?.data?.message || error.message));
    }
  };

  return (<Popconfirm
    title={`${selectedActuarialIds.length > 0 ? selectedActuarialIds.length : total}人にメールを送信することを確認しますか?`}
    onConfirm={handleSendMail}
    okText="送信"
    cancelText="キャンセル"
  >
    <Button type="primary" icon={<MailOutlined />}>メール送信</Button>
  </Popconfirm>);
};

export default SendMailButtonForHome;
