import React, {useState} from 'react';
import {UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import {Button, message, notification, Popconfirm} from 'antd';
import {request} from '@/utils';

const FileUploadButton = () => {

  const [loading, setLoading] = useState(false);
  const handleButtonClick = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';

    fileInput.onchange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
        message.error('No file selected.');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        setLoading(true);
        const response = await request.post('/admin/uploadActuarialData', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setLoading(false);
        if (response.status === '200') {
          message.success(response.message);
          window.location.reload();
        } else {
          notification.error({
            message: 'Error', description: response.message, duration: 0, // 设为0表示不会自动消失
            onClose: () => console.log('Error message closed'),
          });
        }
      } catch (error) {
        // 如果出现网络错误或其他意外错误
        message.error('File upload failed.');
      }
    };

    fileInput.click();
  };

  return (<Popconfirm
    title="アップロード"
    description={(<div>
      <p>
        アップロードするExcelは、以下の画像の順序とフォーマットに厳密に従ってください。そうしないとデータエラーが発生します。
      </p>
      <img src={`${process.env.PUBLIC_URL}/temp/actuarialTempImg.png`} alt="description"
           style={{width: '100%', marginTop: '10px'}} />
      <p>
        または、テンプレートをダウンロードしてアップロードしてください。
      </p>
      <a href={`${process.env.PUBLIC_URL}/temp/actuarialListTemp.xlsx`}
         download="actuarialListTemp.xlsx">精算テンプレート</a>
    </div>)}
    okText="アップロード"
    cancelText="キャンセル"
    onConfirm={handleButtonClick}
  >
    <Button style={{marginRight: '20px'}} type="primary" icon={<UploadOutlined />} loading={loading}>
      アップロード
    </Button>
  </Popconfirm>);
};

export default FileUploadButton;
