import Layout from '@/pages/Layout';
import Login from '@/pages/Login';
import Home from '@/pages/AdminHome/index.js';

import {createHashRouter} from 'react-router-dom';
import {AuthRoute} from '@/components/AuthRoute';
import NotFoundPage from '@/pages/NotFoundPage/NotFoundPage';
import UserHome from '@/pages/UserHome';
import AdminHome from '@/pages/AdminHome/index.js';
import UserSecond from 'src/pages/UserHome/UserSecond';
import AdminSecond from '@/pages/AdminHome/AdminSecond';
import AdminLogs from '@/pages/AdminHome/AdminLogs';
import Help from '@/pages/Login/Help';

const router = createHashRouter([{
  path: '/', element: <AuthRoute><Layout /></AuthRoute>, children: [{
    index: true, element: <Home />,
  }, {
    path: 'adminHome', element: <AdminHome />,
  }, {
    path: 'adminSecond', element: <AdminSecond />,
  }, {
    path: 'adminLogs', element: <AdminLogs />,
  }, {
    path: 'userHome', element: <UserHome />,
  }, {
    path: 'userSecond', element: <UserSecond />,
  }],
}, {
  path: 'login', element: <Login />,
},{
  path: 'help', element: <Help />,
}, {
  path: '*', // 通配符路径，匹配所有未定义的路由
  element: <NotFoundPage />,
}]);

export default router;
