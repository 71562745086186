import {Layout, Menu, Popconfirm} from 'antd';
import {
  HomeOutlined,
  DiffOutlined,
  LogoutOutlined,
  UserOutlined,
  FileTextOutlined,
  CalculatorOutlined,
  UnorderedListOutlined,
  FileSearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import './index.scss';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {clearUserInfo} from '@/store/modules/user';
import {getToken} from '@/utils';
import {jwtDecode} from 'jwt-decode';

const {Header, Sider} = Layout;

const GeekLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedKey = location.pathname;
  const [items, setItems] = useState([]);
  const [collapsed, setCollapsed] = useState(true); // 初始状态为隐藏

  const token = getToken();

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const now = new Date().getTime() / 1000;

        if (decodedToken.exp < now) {
          dispatch(clearUserInfo());
          navigate('/login');
        } else {
          const role = decodedToken.role;
          const sub = decodedToken.sub;
          if (role === 'admin') {
            const adminItems = [{
              label: '精算リスト', key: '/adminHome', icon: <CalculatorOutlined />,
            }, {label: 'ガイドリスト', key: '/adminSecond', icon: <UnorderedListOutlined />}];
            if (sub === 'admin1') {
              adminItems.push({label: 'ログ', key: '/adminLogs', icon: <FileSearchOutlined />});
            }
            setItems(adminItems);
          } else if (role === 'user') {
            setItems([{label: '精算リスト', key: '/userHome', icon: <CalculatorOutlined />}, {
              label: '設定画面', key: '/userSecond', icon: <SettingOutlined />,
            }]);
          }
        }
      } catch (error) {
        dispatch(clearUserInfo());
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [token, dispatch, navigate]);

  const onMenuClick = (route) => {
    const path = route.key;
    navigate(path);
  };

  const onConfirm = () => {
    dispatch(clearUserInfo());
    navigate('/login');
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (<Layout>
    <Header className="header">
      <div className="layout-title">カモメグループ精算システム</div>
      <div className="user-info">
        <span className="user-name"><UserOutlined style={{marginRight: '5px'}} />{jwtDecode(token)?.sub}</span>
        <span className="user-logout">
            <Popconfirm title="ログアウトしますか" okText="ログアウト" cancelText="キャンセル" onConfirm={onConfirm}>
              <LogoutOutlined /> ログアウト
            </Popconfirm>
          </span>
      </div>
    </Header>
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width={170}
             collapsedWidth="var(--collapsed-width)" // 使用CSS变量
             className="site-layout-background custom-collapsed-sider">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[selectedKey]}
          items={items}
          onClick={onMenuClick}
          style={{height: '100%', borderRight: 0}}
        />
      </Sider>
      <Layout className="layout-content" style={{padding: 20}}>
        <Outlet />
      </Layout>
    </Layout>
  </Layout>);
};

export default GeekLayout;
