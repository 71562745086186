import React, {useState} from 'react';
import {Modal, Form, Input, Select, Row, Col, AutoComplete, notification} from 'antd';
import {bankOptions} from './bankOptions'; // 引入银行选项
import branchesData from './branches.json'; // 引入支店数据

const {Option} = AutoComplete;

const UserEditModal = ({visible, isEditMode, form, handleOk, handleCancel}) => {
  const [options, setOptions] = useState(bankOptions);
  const [branches, setBranches] = useState([]);
  const [branchName, setBranchName] = useState('');

  const handleSearch = (value) => {
    setOptions(!value ? bankOptions : bankOptions.filter((option) => option.nameKanji.includes(value) || option.nameKatakana.includes(value) || option.code.includes(value)));
  };

  const handleSelectBank = (value) => {
    const selectedBank = bankOptions.find((bank) => bank.nameKanji === value);
    if (selectedBank) {
      const bankBranches = branchesData[selectedBank.code]?.branches || [];
      setBranches(bankBranches);
      form.setFieldsValue({branchCode: '', branchName: ''});
      setBranchName('');
    }
  };

  const handleBranchCodeChange = (value) => {
    if (value.length === 3 && /^[0-9]{3}$/.test(value)) {
      const branch = branches.find((branch) => branch.code === value);
      if (branch) {
        setBranchName(branch.nameKanji);
        form.setFieldsValue({branchName: branch.nameKanji});
      } else {
        setBranchName('');
        form.setFieldsValue({branchName: ''});
        notification.error({
          message: '支店存在していない', description: '支店コードをご確認ください',
        });
      }
    } else {
      setBranchName('');
      form.setFieldsValue({branchName: ''});
    }
  };

  const renderOption = (option) => (<Option key={option.code} value={option.nameKanji}>
    {option.nameKanji}
  </Option>);

  return (<Modal
    title={isEditMode ? '編集' : '新規ガイド'}
    width={1000}
    open={visible}
    onOk={handleOk}
    onCancel={handleCancel}
    closable={true} // 允许通过点击 "X" 关闭
    maskClosable={false} // 禁止点击遮罩层关闭
  >
    <Form name="user-form" form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="アカウント(電話番号)"
            name="userAccount"
            rules={[{required: true, message: '電話番号を入力してください'}, {
              pattern: /^\d{10,12}$/, message: '電話番号は10-12桁の数字である必要があります',
            }]}
            hasFeedback
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="メールアドレス"
            name="userEmail"
            rules={[{required: false, message: 'メールアドレスを入力してください'}, {
              pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/,
              message: '有効なメールアドレスを入力してください',
            }]}
            hasFeedback
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="アカウント状態"
            name="isActive"
            rules={[{required: true, message: 'アカウント状態を選択してください'}]}
            hasFeedback
          >
            <Select>
              <Select.Option value={true}>アクティブ</Select.Option>
              <Select.Option value={false}>非アクティブ</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="精算先(利用者)"
            name="payee"
            rules={[{required: true, message: '精算先(利用者)を入力してください'}]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="金融機関名"
            name="bankName"
            rules={[{required: false, message: '金融機関名を入力してください'}]}
            hasFeedback
          >
            <AutoComplete
              onSearch={handleSearch}
              onSelect={handleSelectBank}
              placeholder="金融機関名を入力してください"
            >
              {options.map(renderOption)}
            </AutoComplete>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="支店コード"
            name="branchCode"
            rules={[{required: false, message: '支店コードを入力してください'}, {
              pattern: /^[0-9]{3}$/, message: '支店コードは3桁の数字である必要があります',
            }]}
            hasFeedback
          >
            <Input type="text" onChange={(e) => handleBranchCodeChange(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="支店名"
            name="branchName"
            rules={[{required: false, message: '支店名を入力してください'}]}
            hasFeedback
          >
            <Input value={branchName} readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="口座番号"
            name="accountNumber"
            rules={[{required: false, message: '口座番号を入力してください'}, {
              pattern: /^[0-9]{7}$/, message: '口座番号は7桁の数字である必要があります',
            }]}
            hasFeedback
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="口座名義人"
            name="accountHolderName"
            rules={[{required: false, message: '口座名義人を入力してください'}]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Modal>);
};

export default UserEditModal;
