import React, {useEffect, useState} from 'react';
import {Table, Pagination, Spin, Button, Modal} from 'antd';
import {EyeOutlined} from '@ant-design/icons'; // 引入图标
import {request} from '@/utils';

const translateKeys = (key) => {
  const translations = {
    username: '用户名',
    password: '密码',
    userId: '用户ID',
    page: '页码',
    size: '页面大小',
    userEmail: '用户邮箱',
    userAccount: 'アカウント(電話番号)',
    userData: '用户数据',
    isActive: '是否活跃',
    payee: '精算先(利用者)',
    bankName: '金融機関名',
    branchCode: '支店コード',
    branchName: '支店名',
    accountNumber: '口座番号',
    accountHolderName: '口座名義人',
    guideName: 'ガイド名',
    id: '精算ID',
    currentPassword: '旧密码',
    newPassword: '新密码',
    tourName: 'ツアー名',
  };

  return translations[key] || key; // 如果没有匹配到，返回原始键
};

const renderTranslatedParams = (paramString) => {
  if (!paramString) {
    return ''; // 如果参数为空或null，返回空字符串
  }

  try {
    const params = new URLSearchParams(paramString);
    const translatedParts = [];

    params.forEach((value, key) => {
      const translatedKey = translateKeys(key);
      translatedParts.push(
        <span key={key}>
          {translatedKey}: {value}{'　'}
        </span>
      );
    });

    return <>{translatedParts}</>; // 直接返回React组件
  } catch (e) {
    return paramString; // 如果解析失败，显示原始字符串
  }
};

const renderTranslatedText = (jsonString) => {
  try {
    const jsonObj = JSON.parse(jsonString);
    const translatedParts = [];

    Object.keys(jsonObj).forEach((key) => {
      const translatedKey = translateKeys(key);

      if (typeof jsonObj[key] === 'object' && jsonObj[key] !== null) {
        // 如果值是一个对象，递归处理
        Object.keys(jsonObj[key]).forEach((nestedKey) => {
          const translatedNestedKey = translateKeys(nestedKey);
          translatedParts.push(
            <span key={`${key}-${nestedKey}`}>
              {translatedNestedKey}: {jsonObj[key][nestedKey]}{'　'}
            </span>
          );
        });
      } else {
        translatedParts.push(
          <span key={key}>
            {translatedKey}: {jsonObj[key]}{'　'}
          </span>
        );
      }
    });

    return <>{translatedParts}</>; // 返回React元素
  } catch (e) {
    return jsonString; // 如果解析失败，显示原始字符串
  }
};

const getTranslatedURL = (text) => {
  switch (text) {
    case '/api/login':
      return '登录';
    case '/api/admin/createUser':
      return '管理员创建了新导游';
    case '/api/admin/uploadActuarialData':
      return '管理员上传了精算Excel';
    case '/api/admin/updateActuarial':
      return '管理员修改了精算数据';
    case '/api/admin/sendMailByActuarialIds':
      return '管理员发送了精算确认邮件';
    case '/api/user/changePassword':
      return '导游修改了密码';
    case '/api/submit':
      return '导游成功提交签约';
    case '/api/user/verifyAndUpdateEmail':
      return '导游更新提交新邮箱地址';
    case '/api/user/sendVerificationCode':
      return '导游申请更换邮箱的验证码';
    case '/api/admin/updateUser':
      return '管理员更新了导游的个人信息';
    case '/api/admin/downloadActuarialExcel':
      return '管理员下载了精算的Excel';
    case '/api/admin/deleteActuarialData':
      return '管理员删除了精算数据';
    case '/api/admin/createActuarialData':
      return '管理员创建了新的精算数据';
    case '/api/admin/downloadUserExcel"':
      return '管理员下载了导游的Excel';
    case '/api/admin/deleteUser':
      return '管理员删除了导游';
    case '/api/user/downloadActuarialExcel':
      return '导游用户下载了精算的Excel';
    case '/api/admin/uploadUserData':
      return '管理员上传了导游Excel';

    default:
      return text; // 如果没有匹配到，显示原始的 URL
  }
};

const getAdminName = (text) => {
  switch (text) {
    case 'anonymousUser':
      return '新规导游';
    case 'admin1':
      return 'admin1　社長';
    case 'arai':
      return 'arai　新井';
    case 'hanaoka':
      return 'hanaoka　花岡';
    case 'wangziteng':
      return 'wangziteng　王子腾';
    case 'yoshino':
      return 'yoshino　吉野';
    default:
      return text; // 如果没有匹配到，显示原始的 URL
  }
};

const ApiLogTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [detailVisible, setDetailVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const pageSize = 12;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await request.get(`/logs?page=${page - 1}&size=${pageSize}`);
        setData(response.logs); // 从响应中提取 "logs" 字段
        setTotal(response.totalItems); // 从响应中提取 "totalItems" 字段
        setLoading(false);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const showModal = (record) => {
    setModalContent(record);
    setDetailVisible(true);
  };

  const handleDetailClose = () => {
    setDetailVisible(false);
  };


  const modalColumns = [{title: '字段', dataIndex: 'field', key: 'field', width: 150}, {
    title: '内容', dataIndex: 'content', key: 'content',
  }];

  const modalData = [{key: '1', field: 'ID', content: modalContent.id}, {
    key: '2', field: 'URL', content: getTranslatedURL(modalContent.url),
  }, {key: '3', field: 'HTTP方法', content: modalContent.httpMethod}, {
    key: '4', field: '请求参数', content: renderTranslatedParams(modalContent.requestParams),
  }, {
    key: '5', field: '请求体', content: (() => {
      try {
        // 解析 JSON 字符串
        const parsedData = JSON.parse(modalContent.requestBody);

        // 替换 password, currentPassword 和 newPassword 的值
        if (parsedData.password) {
          parsedData.password = '****';
        }
        if (parsedData.currentPassword) {
          parsedData.currentPassword = '****';
        }
        if (parsedData.newPassword) {
          parsedData.newPassword = '****';
        }

        // 将修改后的数据重新转换为 JSON 字符串并返回
        return renderTranslatedText(JSON.stringify(parsedData));
      } catch (error) {
        // 如果解析失败，返回原始文本
        return renderTranslatedText(modalContent.requestBody);
      }
    })(),
  }, {
    key: '6', field: '响应状态', content: modalContent.responseStatus,
  }, {key: '7', field: '响应时间', content: modalContent.responseTime}, {
    key: '8', field: '管理员名', content: getAdminName(modalContent.adminName),
  }, {key: '9', field: '时间戳', content: modalContent.timestamp}];

  const columns = [{
    title: 'ID', dataIndex: 'id', key: 'id', width: 50, // 设置最大宽度为 80px
  }, {
    title: 'URL', dataIndex: 'url', key: 'url', width: 200, ellipsis: true, render: (text) => getTranslatedURL(text),
  }, {
    title: 'HTTPメソッド', dataIndex: 'httpMethod', key: 'httpMethod', width: 120, // 设置最大宽度为 120px
  }, {
    title: 'リクエストパラメータ', dataIndex: 'requestParams', key: 'requestParams', width: 160, // 设置最大宽度为 200px
    ellipsis: true, render: (text) => renderTranslatedParams(text),// 超出部分省略
  }, {
    title: 'リクエストボディ', dataIndex: 'requestBody', key: 'requestBody', width: 200, // 设置最大宽度为 200px
    ellipsis: true, render: (text) => {
      try {
        // 解析 JSON 字符串
        const parsedData = JSON.parse(text);

        // 检查是否包含 password 字段并替换其值
        if (parsedData.password) {
          parsedData.password = '******';
        }
        if (parsedData.currentPassword) {
          parsedData.currentPassword = '******';
        }
        if (parsedData.newPassword) {
          parsedData.newPassword = '******';
        }

        // 将修改后的数据重新转换为 JSON 字符串并显示
        return renderTranslatedText(JSON.stringify(parsedData));
      } catch (error) {
        // 如果解析失败，返回原始文本
        return renderTranslatedText(text);
      }
    },
  }, {
    title: 'レスポンスステータス', dataIndex: 'responseStatus', key: 'responseStatus', width: 150, // 设置最大宽度为 150px
  }, {
    title: 'レスポンスタイム', dataIndex: 'responseTime', key: 'responseTime', width: 150, // 设置最大宽度为 150px
  }, {
    title: '管理者名', dataIndex: 'adminName', key: 'adminName', width: 160, // 设置最大宽度为 200px
    ellipsis: true, render: (text) => getAdminName(text), // 超出部分省略
  }, {
    title: 'タイムスタンプ', dataIndex: 'timestamp', key: 'timestamp', width: 160, // 设置最大宽度为 180px
  }, {
    title: '操作', key: 'action', width: 70, render: (_, record) => (<Button
      type="text"
      icon={<EyeOutlined />} // 使用 EyeOutlined 图标
      onClick={() => showModal(record)}
    />),
  }];

  return (<div>
    {loading ? (<Spin tip="読み込み中..." />) : (<>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={false}
        bordered
      />
      <Pagination
        current={page}
        total={total}
        pageSize={pageSize}
        onChange={(page) => setPage(page)}
        style={{marginTop: '16px', textAlign: 'center'}}
      />
    </>)}
    <Modal
      title="詳細情報"
      open={detailVisible}
      onOk={handleDetailClose}
      onCancel={handleDetailClose}
      footer={null}
      width={800}
    >
      <Table
        columns={modalColumns}
        dataSource={modalData}
        pagination={false}
        showHeader={false}
        size="small"
      />
    </Modal>
  </div>);
};

export default ApiLogTable;

