import React, {useState} from 'react';
import {Modal, Button, Form, Input, message} from 'antd';
import {request} from '@/utils';

const UpdateEmailModal = ({visible, onCancel, userId, userEmail}) => {
  const [form] = Form.useForm();
  const [countdown, setCountdown] = useState(0); // 倒计时状态

  const handleSendVerification = async () => {
    try {
      const values = await form.validateFields(['email']);
      const response = await request.post('/user/sendVerificationCode', {
        userEmail: values.email,
      });

      if (response.status === '200') {
        message.success('验证码已发送到您的邮箱');
        form.setFieldsValue({verificationCode: ''});
        startCountdown(); // 开始倒计时
      } else {
        message.error(`发送验证码失败: ${response.message}`);
      }
    } catch (error) {
      message.error('发送验证码失败，请稍后重试');
    }
  };

  const startCountdown = () => {
    setCountdown(60); // 设置倒计时 60 秒
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleUpdateEmail = async () => {
    try {
      const values = await form.validateFields();
      const response = await request.post('/user/verifyAndUpdateEmail', {
        userId, // 直接使用传入的 userId
        code: values.verificationCode, // 验证码输入框的值
        userEmail: values.email,
      });

      if (response.status === '200') {
        message.success('邮箱更新成功');
        window.location.reload(); // 刷新页面
        onCancel(); // 关闭 Modal
        form.resetFields(); // 重置表单
      } else {
        message.error(`邮箱更新失败: ${response.message}`);
      }
    } catch (error) {
      message.error('邮箱更新失败，请稍后重试');
    }
  };

  return (<Modal
    title="メールアドレス更新"
    visible={visible}
    onCancel={onCancel}
    footer={[<Button key="cancel" onClick={onCancel}>
      キャンセル
    </Button>, <Button key="send" type="primary" onClick={handleSendVerification} disabled={countdown > 0}>
      {countdown > 0 ? `重新获取(${countdown}s)` : '获取验证码'}
    </Button>, <Button key="update" type="primary" onClick={handleUpdateEmail}>
      更新邮箱
    </Button>]}
    closable={true} // 允许通过点击 "X" 关闭
    maskClosable={false} // 禁止点击遮罩层关闭
  >
    <Form form={form} layout="vertical" initialValues={{email: userEmail}}>
      <Form.Item
        name="email"
        label="新邮箱地址"
        rules={[{required: true, message: '请输入您的新邮箱地址'}, {type: 'email', message: '请输入有效的邮箱地址'}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="verificationCode"
        label="验证码"
        rules={[{required: true, message: '请输入验证码'}]}
      >
        <Input />
      </Form.Item>
    </Form>
  </Modal>);
};

export default UpdateEmailModal;
