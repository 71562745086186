import React from 'react';
import {Modal, Button, Input, Form} from 'antd';
import './ChangePasswordModal.css'; // 引入自定义样式

const ChangePasswordModal = ({visible, onCancel, onFinish, userId, username}) => {
  const handleFinish = (values) => {
    onFinish({
      ...values, userId, username,
    });
  };

  return (<Modal
    title="パスワード変更"
    visible={visible}
    onCancel={onCancel}
    footer={null}
    className="custom-change-password-modal"
  >
    <Form onFinish={handleFinish} className="custom-password-change-form">
      <Form.Item
        label="現在のパスワード"
        name="currentPassword"
        rules={[{required: true, message: '現在のパスワードを入力してください'}]}
        className="custom-form-item"
      >
        <Input.Password className="custom-input" />
      </Form.Item>
      <div className="spacer"></div>
      {/* 添加间隔 */}
      <Form.Item
        label="新しいパスワード"
        name="newPassword"
        rules={[{required: true, message: '新しいパスワードを入力してください'}, {
          min: 8, message: 'パスワードは8文字以上です',
        }, {max: 18, message: 'パスワードは18文字以下です'}, {
          pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/, message: '英字と数字の両方を含める必要があります',
        }]}
        className="custom-form-item"
      >
        <Input.Password className="custom-input" />
      </Form.Item>
      <Form.Item
        label="新パスワード確認"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[{required: true, message: '新しいパスワードを再入力してください'}, ({getFieldValue}) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('新しいパスワードが一致しません'));
          },
        })]}
        className="custom-form-item"
      >
        <Input.Password className="custom-input" />
      </Form.Item>
      <div style={{marginBottom: '-10px'}}>※半角英字と半角数字を組み合わせ、8桁以上18桁以下で記入ください。記号不可。
      </div>
      <Form.Item className="custom-modal-footer">
        <Button type="primary" htmlType="submit" className="custom-modal-submit-button">
          変更
        </Button>
        <Button onClick={onCancel} className="custom-modal-cancel-button">
          戻る
        </Button>
      </Form.Item>
    </Form>
  </Modal>);
};

export default ChangePasswordModal;
